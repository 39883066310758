import { MDBCard, MDBCardBody, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import React from 'react';
import FaIcon from '../../../Global/Widgets/FaIcon';
import ProfileFeed from '../../Widgets/FeedWidgets/ProfileFeed';

const DesktopFeed = ({ state }) => {
    return (
        <MDBRow>
        <MDBCol size={4}>
            <MDBCard className="fb-styled-card details">
                <MDBCardBody>
                    <h4 className="fb-style-title">Bemutatkozás</h4>
                    <span className="fb-style-text" dangerouslySetInnerHTML={{ __html:state.profile.aboutme }}></span>
                    <hr/>
                    <b><FaIcon type="solid" icon="walkie-talkie" /> Rádiók</b><br/>
                    {state.infos.radios.map((radio,index) => ( <div className="infoItem" key={`radio-${index}`}>{radio}</div> ))}
                    <hr/>
                    <b><FaIcon type="solid" icon="tower-cell" /> Antennák</b><br/>
                    {state.infos.antennas.map((ant,index) => ( <div className="infoItem" key={`antenna-${index}`}>{ant}</div> ))}
                    <hr/>
                    <b><FaIcon type="solid" icon="wave-sine" /> Frekvenciák</b><br/>
                    {state.infos.freqs.map((freq,index) => ( <div className="infoItem" key={`freq-${index}`}>{freq}</div> ))}
                    <hr/>
                </MDBCardBody>
            </MDBCard>
        </MDBCol>
        <MDBCol size={8}>
            <ProfileFeed state={state}/>
        </MDBCol>
    </MDBRow>
    );
};

export default DesktopFeed;