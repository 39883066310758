import { MDBCol } from 'mdb-react-ui-kit';
import React, { useEffect, useRef, useState } from 'react';
import { Form, Container, InputGroup, Row, ButtonGroup, Button, Overlay, Alert } from 'react-bootstrap';
import client from '../../Utils/apiClient';
import MyStorage from '../../Utils/MyStorage';
import { Editor } from '@tinymce/tinymce-react';
import { validatePasswordForm, validatePersonaForm } from '../../Utils/validation';

const DesktopEditProfile = () => {
    const uid = MyStorage.local.get('user').uid;
    const editorRef = useRef();
    const callsignRef = useRef(null);
    const opNameRef = useRef(null);
    const emailRef = useRef(null);
    const oldpwRef = useRef(null);
    const newpwRef = useRef(null);
    const newpwrepRef = useRef(null);
    const [state, setState] = useState({
        fields: {
            callsign: '',
            opName: '',
            email: '',
            old_password: '',
            new_password: '',
            new_password_rep: '',
            aboutme: '',
            avatar: '',
            coverImage: '',
            radios: [],
            antennas: [],
            freqs: []
        },
        errors: {},
    });
    const handleChange = (event) => {
        let fields = state.fields;
        let errors = {};
        let field = event.target.name;
        let value = event.target.value;
        fields[field] = value;
        setState({...state, fields });
        setState({...state, errors });
    }
    const loadSettings = async () => { await client.post('/getProfileForSettings', {uid: uid}, (data) => { let fields = []; fields = data.fields; setState({...state, fields}); },MyStorage.local.get('token')); }
    useEffect(() => { loadSettings(); },[]);
    useEffect(() => { if(editorRef.current){ editorRef.current.setContent(state.fields.aboutme || ''); }; },[state.fields.aboutme]);

    const onSubmitPersona = async (event) => {
        event.preventDefault();
        var payload = validatePersonaForm(state.fields);
        if(payload.success){
            setState({...state,errors: {} });
            await client.post('/updatePersona', {callsign: state.fields.callsign, opName: state.fields.opName, aboutme: editorRef.current.getContent(), uid: uid}, (data) => {
                if(!data.success){
                    let errors = {personaMessage: {variant: "danger", text: data.message}};
                    setState({...state, errors});
                }else{
                    let errors = {personaMessage: {variant: "success", text: data.message}};
                    setState({...state, errors});
                }
            },MyStorage.local.get('token'));
        }else{
            const errors = payload.errors;
            setState({...state, errors });
        };
    }
    const onSubmitPassword = async (event) => {
        event.preventDefault();
        var payload = validatePasswordForm(state.fields);
        if(payload.success){
            setState({...state,errors: {} });            
            await client.post('/updatePassword', {curr_password: state.fields.old_password, password: state.fields.new_password, uid: uid}, (data) => {                
                
                let errors = null;
                if(data.success){
                    errors = {passwordMessage: {variant: "success", text: data.message}};
                }else{
                    errors = {passwordMessage: {variant: "danger", text: data.message}};
                };                                
                setState({...state, errors});
                setState((prevState) => ({...prevState,fields: {...prevState.fields,old_password: '', new_password: '', new_password_rep: ''}}));
                console.log(state);
            },MyStorage.local.get('token'));
        }else{
            const errors = payload.errors;
            setState({...state, errors });
        };
    }
    const onSubmitEmail = async (event) => {
        event.preventDefault();
        setState({...state,errors: {} });  
        await client.post("/updateEmail", {email: state.fields.email, uid: uid}, (data) => {
            let errors = null;
            if(data.success){ 
                errors = {emailMessage: {variant: "success", text: data.message}}; 
            }else{ 
                errors = {emailMessage: {variant: "danger", text: data.message}}; 
            };
            setState({...state, errors});
        },MyStorage.local.get('token'));
    }

    
    const handleEMSGClick = (event) => { setState({...state, errors: {} }); }

    return (
        <Container className="mt-base">
            <Row>
                <MDBCol size={4}>
                    <div className="fb-styled-card" style={{paddingTop:'10px',paddingLeft:'5px',paddingRight:'5px'}}>
                        <h4 className="text-center">Hívójel és Operátornév</h4>
                        <Form onSubmit={onSubmitPersona} style={{paddingTop: '10px', paddingBottom: '10px'}}>
                            {(state.errors?.personaMessage?.text) && (<Alert variant={state.errors?.personaMessage?.variant} onClick={() => setState({...state, errors: {}})}>{state.errors?.personaMessage.text}</Alert>)}
                            <InputGroup className="mb-2">
                                <Form.Control className={`${state.errors?.callsign ? "is-invalid" : ""}`} ref={callsignRef} type="text" placeholder="Hívójel" onChange={handleChange} value={state.fields.callsign} name="callsign" />
                                <ErrorMessage state={state} target={callsignRef} placement="right" field="callsign" handleClick={handleEMSGClick}/>
                            </InputGroup>
                            <InputGroup className="mb-2">
                                <Form.Control className={`${state.errors?.opName ? "is-invalid" : ""}`} ref={opNameRef} type="text" placeholder="Operátor név" onChange={handleChange} value={state.fields.opName} name="opName" />
                                <ErrorMessage state={state} target={opNameRef} placement="right" field="opName" handleClick={handleEMSGClick}/>
                            </InputGroup>
                            {/*<InputGroup className="mb-2">
                                <Form.Control className={`${state.errors?.email ? "is-invalid" : ""}`} ref={emailRef} type="email" placeholder="E-mail cím" onChange={handleChange} value={state.fields.email} name="email" />
                                <ErrorMessage state={state} target={emailRef} placement="right" field="email" handleClick={handleEMSGClick}/>
                            </InputGroup>*/}
                            <Editor 
                                apiKey="ihuzhtbtyuzbwks4d8nuyl9bmu1uq25scfmxd8gjgmmqc5qz"
                                onInit={(_evt, editor) => editorRef.current = editor}
                                init={{
                                    skin: "oxide-dark",
                                    content_css: "dark",
                                    menubar: false,
                                    toolbar: 'undo redo | bold italic'
                                }}>
                            </Editor>
                            <ButtonGroup style={{marginTop:'7px'}} className="mb-2">
                                <Button type="submit" variant="success">Mentés</Button>
                            </ButtonGroup>
                        </Form>
                    </div>
                </MDBCol>

                <MDBCol size={4}>
                    <div className="fb-styled-card mb-3" style={{paddingTop:'10px',paddingLeft:'5px',paddingRight:'5px'}}>
                        <h4 className="text-center">Jelszómódosítás</h4>                        
                        <Form onSubmit={onSubmitPassword} style={{paddingTop: '10px', paddingBottom: '10px'}}>
                            {(state.errors?.passwordMessage?.text) && (
                            <Alert variant={state.errors?.passwordMessage?.variant} onClick={() => setState({...state, errors: {}})}>{state.errors?.passwordMessage.text}</Alert>
                            )}
                            <InputGroup className="mb-2">
                                <Form.Control className={`${state.errors?.old_password ? "is-invalid" : ""}`} ref={oldpwRef} type="password" placeholder="Jelenlegi jelszó" onChange={handleChange} value={state.fields.old_password} name="old_password" />
                                <ErrorMessage state={state} target={oldpwRef} placement="right" field="old_password" handleClick={handleEMSGClick}/>
                            </InputGroup>
                            <InputGroup className="mb-2">
                                <Form.Control className={`${state.errors?.new_password ? "is-invalid" : ""}`} ref={newpwRef} type="password" placeholder="Új jelszó" onChange={handleChange} value={state.fields.new_password} name="new_password" />
                                <ErrorMessage state={state} target={newpwRef} placement="right" field="new_password" handleClick={handleEMSGClick}/>
                            </InputGroup>
                            <InputGroup className="mb-2">
                                <Form.Control className={`${state.errors?.new_password_rep ? "is-invalid" : ""}`} ref={newpwrepRef} type="password" placeholder="Új jelszó megerősítése" onChange={handleChange} value={state.fields.new_password_rep} name="new_password_rep" />
                                <ErrorMessage state={state} target={newpwrepRef} placement="right" field="new_password_rep" handleClick={handleEMSGClick}/>
                            </InputGroup>
                            <ButtonGroup style={{marginTop:'7px'}} className="mb-2">
                                <Button type="submit" variant="success">Mentés</Button>
                            </ButtonGroup>
                        </Form>
                    </div>

                    <div className="fb-styled-card mb-3" style={{paddingTop:'10px',paddingLeft:'5px',paddingRight:'5px'}}>
                        <h4 className="text-center">E-mail cím módosítás</h4>                        
                        <Form onSubmit={onSubmitEmail} style={{paddingTop: '10px', paddingBottom: '10px'}}>
                            {(state.errors?.emailMessage?.text) && (
                            <Alert variant={state.errors?.emailMessage?.variant} onClick={() => setState({...state, errors: {}})}>{state.errors?.emailMessage.text}</Alert>
                            )}
                            <InputGroup className="mb-2">
                                <Form.Control className={`${state.errors?.old_password ? "is-invalid" : ""}`} ref={oldpwRef} type="email" placeholder="E-mail cím" onChange={handleChange} value={state.fields.email} name="email" />
                                <ErrorMessage state={state} target={oldpwRef} placement="right" field="old_password" handleClick={handleEMSGClick}/>
                            </InputGroup>
                            <ButtonGroup style={{marginTop:'7px'}} className="mb-2">
                                <Button type="submit" variant="success">Mentés</Button>
                            </ButtonGroup>
                        </Form>
                    </div>
                </MDBCol>
            </Row>
        </Container>
    );
};

const ErrorMessage = ({target,state,placement,field,handleClick}) => {
    return (
        <Overlay target={target.current} show={state.errors?.[field]} placement={placement}>
            <div style={{position:'absolute',backgroundColor: 'rgba(255, 100, 100, 0.85)',padding: '2px 10px',color: 'white',borderRadius: 3,}} onClick={handleClick}>
                {state.errors?.[field]}
            </div>
        </Overlay>
    );
}


export default DesktopEditProfile;