import React, { useEffect, useState } from 'react';
import MyStorage from '../../Utils/MyStorage';
import ProfileHeader from '../Widgets/ProfilHeader';
import { Button, Container } from 'react-bootstrap';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import DesktopFeed from './Profile/DesktopFeed';
import QSOMap from './Profile/QSOMap';

const DesktopProfile = () => {
    const location = useLocation();
    const [state, setState] = useState({
        profile: {
            uid: '',
            displayName: '',
            email: '',
            avatar: '',
            coverImage: ''
        },
        infos: {
            radios: [],
            antennas: [],
            freqs: []
        },
        counts: {
            logs: 0,
            follower: 0
        }
    })
    const updateProfile = async () => {
        return new Promise((resolve,reject) => {
            let profile = state.profile;
            let infos = state.infos;
            let counts = state.counts;
            const user = MyStorage.local.get('user');
            profile['uid'] = user.uid;
            profile['aboutme'] = user.aboutme;
            profile['displayName'] = user.callsign;
            profile['email'] = user.email;
            profile['avatar'] = (user?.avatar) ? user?.avatar : "https://static.446.hu/images/noavatar.png";
            profile['cover'] = (user?.coverImage) ? user?.coverImage : "https://static.446.hu/images/cover_stock.jpg";
            profile['perm'] = user.perm;
            infos['radios'] = JSON.parse(user.radios);
            infos['antennas'] = JSON.parse(user.antennas);
            infos['freqs'] = JSON.parse(user.freqs);
            counts['logs'] = user.qso_out_count;
            setState({...state, profile});
            setState({...state, infos});
            setState({...state, counts});
            resolve(profile);
        });
    }
    const headerCallback = (event) => {switch(event){case "updated:cover": case "update:profile": updateProfile(); break;default: break;}}
    const fetches = async () => { await updateProfile(); }    
    useEffect(() => { fetches(); console.log(location.pathname); },[]);

    return (
        <>
            <div className="fb-styled-card mb-3" style={{paddingBottom: "5px", marginTop: "calc(55px + 7px)"}}>
                <ProfileHeader user={state} isMyOwn={true} callback={(event) => headerCallback(event) } />
                <hr style={{ marginTop: "45px" }}/>
                <Container>
                    <MDBRow>
                        <MDBCol>
                            <Button as={Link} to="/profile" variant={`fb-header-btn ${location.pathname === "/profile" ? "active" : ""}`}>Bejegyzések</Button>
                            <Button as={Link} to="/profile/map" variant={`fb-header-btn ${location.pathname === "/profile/map" ? "active" : ""}`}>Térkép</Button>
                        </MDBCol>
                    </MDBRow>
                </Container>
            </div>
            <Container>
                <Routes>
                    <Route path="/" element={<DesktopFeed state={state}/>} />
                    <Route path="/map" element={<QSOMap user={state.profile}/>} />
                </Routes>                
            </Container>
        </>
    );
};

export default DesktopProfile;