const MyStorage = {
    local : {
        get(key){
            return JSON.parse(localStorage.getItem(`app446hu/storage/${key}`) || null);
        },
        put(key,value){
            return localStorage.setItem(`app446hu/storage/${key}`, JSON.stringify(value));
        },
        remove(key){
            return localStorage.removeItem(`app446hu/storage/${key}`);
        }
    },
    session : {
        get(key){
            return JSON.parse(sessionStorage.getItem(`app446hu/storage/${key}`) || null);
        },
        put(key,value){
            return sessionStorage.setItem(`app446hu/storage/${key}`, JSON.stringify(value));
        },
        remove(key){
            return sessionStorage.removeItem(`app446hu/storage/${key}`);
        }
    },
    message: {
        getAll(){
            return JSON.parse(localStorage.getItem(`app446hu/messages`) || null);
        },
        get(key){
            return JSON.parse(localStorage.getItem(`app446hu/messages/${key}`) || null);
        },
        put(key,value){
            return localStorage.setItem(`app446hu/messages/${key}`, JSON.stringify(value));
        },
        remove(key){
            return localStorage.removeItem(`app446hu/messages/${key}`);
        }
    }
}

export default MyStorage;