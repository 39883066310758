import React from 'react';
import { ListGroup } from 'react-bootstrap';
import FaIcon from '../../../Global/Widgets/FaIcon';
import { Link } from 'react-router-dom';

const SidebarMenu = () => {
    return (
        <>
        <ListGroup className="mb-3 fb-style-list">
            <ListGroup.Item as={Link} to="/terkep"><FaIcon type="solid" icon="map" /> Térkép</ListGroup.Item>
            <ListGroup.Item as={Link} to="/qso_log"><FaIcon type="solid" icon="signal-stream" /> QSO beküldés</ListGroup.Item>
            <ListGroup.Item as={Link} to="/qso_list"><FaIcon type="solid" icon="chart-simple" /> Beküldött QSO-k</ListGroup.Item>
            <ListGroup.Item as={Link} to="/downloads"><FaIcon type="solid" icon="download" /> Letöltések</ListGroup.Item>
        </ListGroup>
        <hr className="mb-3"/>
        </>
    );
};

export default SidebarMenu;