import React, { useEffect, useState } from 'react';
import { Link, Route, Routes, useLocation, useParams } from 'react-router-dom';
import MyStorage from '../../Utils/MyStorage';
import client from '../../Utils/apiClient';
import ProfileHeader from '../Widgets/ProfilHeader';
import { Button, Container } from 'react-bootstrap';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import DesktopFeed from './Profile/DesktopFeed';
import QSOMap from './Profile/QSOMap';

const DesktopProfilePublic = () => {
    const { num } = useParams();
    const location = useLocation();
    const [isMyOwn, setIsMyOwn] = useState(false);
    const [state, setState] = useState({
        profile: {
            uid: '',
            displayName: '',
            email: '',
            avatar: '',
            coverImage: ''
        },
        infos: {
            radios: [],
            antennas: [],
            freqs: []
        },
        counts: {
            logs: 0,
            follower: 0
        }
    })
    const getProfile = async () => {
        return new Promise((resolve, reject) => {
            let profile = state.profile;
            let infos = state.infos;
            let counts = state.counts;
            client.post('/getProfile', { uid: num }, (data) => {
                profile['uid'] = num;
                profile['aboutme'] = data.aboutme;
                profile['displayName'] = data.callsign;
                profile['email'] = data.email;
                profile['avatar'] = (data?.avatar) ? data?.avatar : "https://static.446.hu/images/noavatar.png";
                profile['cover'] = (data?.coverImage) ? data?.coverImage : "https://static.446.hu/images/cover_stock.jpg";
                profile['perm'] = data.perm;
                infos['radios'] = JSON.parse(data.radios);
                infos['antennas'] = JSON.parse(data.antennas);
                infos['freqs'] = JSON.parse(data.freqs);
                counts['logs'] = data.qso_out_count;
                setState({ ...state, profile });
                setState({ ...state, infos });
                setState({ ...state, counts });
                resolve(profile);
            }, MyStorage.local.get('token'));
        });
    }

    useEffect(() => {
        setIsMyOwn((MyStorage.local.get('user').uid == num) ? true : false);
        getProfile();
    }, []);

    return (
       <>
            <div className="fb-styled-card mb-3" style={{paddingBottom: "5px"}}>
                <ProfileHeader user={state} isMyOwn={isMyOwn} callback={(event) => {} } />
                <hr style={{ marginTop: "45px" }}/>
                <Container>
                    <MDBRow>
                        <MDBCol>
                            <Button as={Link} to={`/profile/${num}`} variant={`fb-header-btn ${location.pathname === `/profile/${num}` ? "active" : ""}`}>Bejegyzések</Button>
                            <Button as={Link} to={`/profile/${num}/map`} variant={`fb-header-btn ${location.pathname === `/profile/${num}/map` ? "active" : ""}`}>Térkép</Button>
                        </MDBCol>
                    </MDBRow>
                </Container>
            </div>
            <Container>
                <Routes>
                    <Route path="/" element={<DesktopFeed state={state}/>} />
                    <Route path="/map" element={<QSOMap user={state.profile}/>} />
                </Routes>                
            </Container>
       </>
    );
};

export default DesktopProfilePublic;