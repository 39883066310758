import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, Polyline, TileLayer, Tooltip, useMap } from 'react-leaflet';
import MarkerCombined from '../../Global/Components/Terkep/MarkerCombined';
import { calculateDistance } from '../../Utils/LocationService';

const FitMapToBounds = ({ pointA, pointB }) => {
    const map = useMap();
    useEffect(() => {
        const bounds = [pointA, pointB];
        map.fitBounds(bounds, { padding: [5, 5] });
    }, [pointA, pointB, map]);
}

const TerkepSmall = ({ posA, posB, posC = null, my, rem, args }) => {
    const [center, setCenter] = useState([47.1628, 19.5036]);
    const mapRef = useRef();
    useEffect(() => {
        let _center = [((posA[0] + posB[0]) / 2), ((posA[1] + posB[1]) / 2)];
        setCenter(_center);
    }, []);

    return (
        <MapContainer
            ref={mapRef}
            center={center}
            zoom={6}
            scrollWheelZoom={false}
            dragging={false}
            doubleClickZoom={false}
            zoomControl={false}
            id='mapPicture'
            style={{
                width: 'clamp(250px, 100vw, 100%)',
                height: 'clamp(200px, 100vh, 250px)',
                
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    zIndex: 1000, // Magas z-index, hogy a térkép fölött legyen
                }}
            ></div>
            <TileLayer
                attribution=''
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <FitMapToBounds
                pointA={[posA[0], posA[1]]}
                pointB={[posB[0], posB[1]]}
            />
            <MarkerCombined
                key={0}
                args={{
                    id: 0,
                    type: 'handradio',
                    lat: posA[0],
                    lon: posA[1],
                    title: my.title,
                    description: my.description
                }}
                eventAction={() => { }}
                callback={() => { }}
            />
            <MarkerCombined
                key={1}
                args={{
                    id: 0,
                    type: 'handradio',
                    lat: posB[0],
                    lon: posB[1],
                    title: rem.title,
                    description: rem.description
                }}
                eventAction={() => { }}
                callback={() => { }}
            />
            {(posC != null) && (
                <MarkerCombined
                    key={2}
                    args={{
                        id: 0,
                        type: args.markerType,
                        lat: posC[0],
                        lon: posC[1],
                        title: rem.title,
                        description: rem.description
                    }}
                    eventAction={() => { }}
                    callback={() => { }}
                />
            )}
            <Polyline
                pathOptions={{ color: 'red' }}
                positions={[
                    [posA[0], posA[1]],
                    ...(posC ? [[posC[0], posC[1]]] : []),
                    [posB[0], posB[1]]
                ]}
            >
            </Polyline>
        </MapContainer>
    )
};

export default TerkepSmall;