import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import FaIcon from '../../Global/Widgets/FaIcon';
import MyStorage from '../../Utils/MyStorage';
import { count } from 'firebase/firestore';
import client from '../../Utils/apiClient';
import { Link } from 'react-router-dom';

const StatBar = () => {
    const [ state, setState ] = useState({
        logs: {
            count: 0
        }
    });

    const countQSORefs = () => {
        let mycs = MyStorage.local.get('user');
        client.post('qthcount', {callsign: mycs.displayName}, (data) => {
            let logs = state.logs;
            logs['count'] = data.count;
            setState({...state, logs});
        },MyStorage.local.get('token'));
    };

    useEffect(() => {
        countQSORefs();
    },[]);


    return (
        <Row>
            <Col xs={6}>
                <Card data-bs-theme="dark" style={{ marginTop: "0", width: "100%" }} className="mb-3">
                    <Card.Body>
                        <Row>
                            <Col xs={4}>
                                <FaIcon type="solid" icon="chart-simple" size="3x"/>
                            </Col>
                            <Col xs={8} className="text-right">
                                <h5>{state.logs.count}</h5>
                                <span>QSO log</span>
                            </Col>
                        </Row>
                        <Link to="/qso_list" className="stretched-link"></Link>
                    </Card.Body>
                </Card>
            </Col>
            
        </Row>
    );
};

export default StatBar;