import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import FaIcon from '../../../Global/Widgets/FaIcon';
import client from '../../../Utils/apiClient';
import MyStorage from '../../../Utils/MyStorage';
import { Link } from 'react-router-dom';

const ProfileFeedComments = ({ user, item }) => {
    
    const [commentsNumber, setCommentsNumber] = useState(0);
    const [comments, setComments] = useState([]);

    

    const getComments = async () => {
        await client.post('/getComments', { id: item.id }, (data) => {
            setCommentsNumber(data.comment.length);
            setComments(data.comment);
        }, MyStorage.local.get('token'));
    }

    const cb_form_post = async () => {
        await getComments();
    }

    useEffect(() => {
        getComments();
    }, []);
    return (
        <>
            {(commentsNumber > 0) && (<CommentsBlock user={user} post={item} comments={comments} count={commentsNumber} callback={cb_form_post}/>)}
            <CommentForm user={user} item={item} callback={cb_form_post}/>
        </>
    );
};

const CommentsBlock = ({ user, post, comments, count, callback = {} }) => {
    const [show, setShow] = useState(false);
    const cb_form = () => {
        callback();
    }
    return (
        <>
            <Modal key={post.id} show={show} onHide={() => setShow(false)} className="modal-comments">
                <Modal.Header closeButton>
                    <Modal.Title key={post.id}>{post.callsign} bejegyzése</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-scrolled-body">
                    {comments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((item, index) => { return <CommentItem item={item} /> })}
                </Modal.Body>
                <Modal.Footer>
                    <CommentForm user={user} item={post} onModal={true} callback={cb_form}/>
                </Modal.Footer>
            </Modal>
            {(count > 3) && (<div onClick={() => setShow(true)} className="fb-style-span-link">További hozzászólások</div>)}
            {comments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).slice(0, 3).map((item, index) => { return <CommentItem key={index} index={index} item={item} /> })}
        </>
    );
}

const CommentItem = ({ item, index }) => {

    return (
        <MDBRow key={index} style={{ marginTop: '5px', marginBottom: '5px' }} >
            <MDBCol size={1}>
                <img src={item.avatar} alt="profilePic" className="profile-thumb" />
            </MDBCol>
            <MDBCol size={11}>
                <div className="fb-styled-comment-box" style={{ paddingLeft: '10px', paddingTop: '5px', paddingRight: '5px', paddingBottom: '10px', borderRadius: '10px' }}>
                    <Link to={`/profile/${item.user_id}`}>{item.callsign}</Link><br />
                    <span>{item.content}</span>
                </div>
            </MDBCol>
        </MDBRow>)
}

const CommentForm = ({ user, item, onModal = false, callback = {} }) => {
    const [cValue, setCValue] = useState('');
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const handleChange = (event) => {
        setSubmitDisabled((event.target.value.length > 0) ? false : true);
        setCValue(event.target.value);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        await client.post('/addComment', { feed_id: item.id, user_id: user.uid, comment: cValue }, (data) => {
            setCValue('');
            setSubmitDisabled(true);
            callback();
        }, MyStorage.local.get('token'));
    };
    return (
        <MDBRow style={{ paddingTop: '10px' }}>
            <MDBCol size={1}>
                <img src={user.avatar} alt="profilePic" className="profile-thumb" />
            </MDBCol>
            <MDBCol size={11}>
                <Form onSubmit={handleSubmit}>
                    <InputGroup>
                        <Form.Control type="text" className="fbstyle" onChange={handleChange} value={cValue} placeholder={`Válasz mint ${user.callsign}`}></Form.Control>
                        <Button type="submit" disabled={submitDisabled} variant="fb-field-button"><FaIcon type="solid" icon="paper-plane-top" /></Button>
                    </InputGroup>
                </Form>
            </MDBCol>
        </MDBRow>
    )
}

export default ProfileFeedComments;