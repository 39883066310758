import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import client from '../../../Utils/apiClient';
import MyStorage from '../../../Utils/MyStorage';
import { Link, useLocation } from 'react-router-dom';
import FaIcon from '../../../Global/Widgets/FaIcon';

const DesktopDownloadsListCategory = () => {
    const [cats, setCats] = useState([]);
    
    useEffect(() => {
        

        client.get('/getDlCategories', (data) => {
            setCats(data.cats);
        },MyStorage.local.get('token'));
    },[]);
    
    return (
        <Row>
            {(cats.map((item,index) => (
                <Col md={4}>
                    <Card className="fb-styled-card">
                        <Card.Img variant="top" src={item.image} />
                        <Card.Body>
                            <Card.Title>{item.name}</Card.Title>
                            <Card.Text>{item.description}</Card.Text>
                            <Button variant="fb-field-button" as={Link} to={`/downloads/${item.alias}`}>
                                <FaIcon type="solid" icon="folder-open" />
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            )))}
        </Row>
    );
};

export default DesktopDownloadsListCategory;