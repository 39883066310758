import React from 'react';
import { getAuth, signOut } from 'firebase/auth';
import Cookies from 'js-cookie';

const Logout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
        Cookies.remove('auth');
        Cookies.remove('token');
        window.location.replace('http://auth.446.hu');
    })
    return (
        <div>
            
        </div>
    );
};

export default Logout;