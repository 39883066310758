import React, { useEffect, useState } from 'react';
import { getDistanceByQTH, getMaidenheadGrid, getUserLocation } from '../../Utils/LocationService';
import { isMobile } from 'react-device-detect';
import MyStorage from '../../Utils/MyStorage';
import { Accordion, Alert, Button, ButtonGroup, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import FaIcon from '../../Global/Widgets/FaIcon';
import client from '../../Utils/apiClient';


const MobileQSOSend = () => {
    const [formShow, setFormShow] = useState(false);
    const [qthCssClass, setQthCssClass] = useState('');
    const [state, setState] = useState({
        message: '',
        user:{
            myQth: '',
            myName: ''
        },
        qth:{
            qth: '',
            date: '',
            callsign: '',
            opName: '',
            freq: '',
            ctcs: '',
            type: '/',
            mode: '/D'
        },
        qthList: []
    });

    useEffect(() => {
        if(isMobile){
            getUserLocation()
            const {lat,lon} = MyStorage.local.get('location') || {lat: null, lon: null};
            let user = state.user;
            if(lat != null && lon != null){
                const qth = getMaidenheadGrid(lat,lon);                
                user['myQth'] = qth;
                setQthCssClass('is-valid');
            }else{
                setQthCssClass('is-invalid');
            }

            const _user = MyStorage.local.get('user');
            user['myName'] = _user.displayName;

            setState({...state, user});
        };
    },[]);

    const handleChange = (event) => {
        const field = event.target.name;
        if(field === "myQth" && event.target.value.length >= 6){ setQthCssClass('is-valid'); };
        let user = state.user;
        user[field] = event.target.value;
        setState({ ...state, user });
    };
    const handleQTHChange = (event) => {
        const field = event.target.name;
        let qth = state.qth;
        qth[field] = event.target.value;
        setState({ ...state, qth });
    };

    const submitQTHForm = async (event) => {
        event.preventDefault();
        let qth = state.qth;
        setState((prevState) => ({
            ...prevState,
            qthList: [...prevState.qthList, qth],
            qth: {qth: '',date: '',callsign: '',opName: '',freq: '',ctcs: '',type: '/',mode: '/D'}
        }));
        setFormShow(false);
    }

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // A hónap 0-tól 11-ig terjed, ezért +1
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const submitForm = async (event) => {
        event.preventDefault();
        
        let fields = {
            my_callsign: state.user.myName,
            my_qth: state.user.myQth,
            list: JSON.stringify(state.qthList)
        };
        client.post('qthadd', fields, (data) => {
            console.log(data);
        }, MyStorage.local.get('token'));
        
        setState((prevState) => ({
            ...prevState,
            qthList: [],
            message: 'A logok sikeresen beküldve!'
        }));
    }

    return (
        <Container className="mobileContainer">
            <Modal show={formShow} onHide={() => {setFormShow(false)}}>
                <Form onSubmit={submitQTHForm}>
                    <Modal.Header closeButton>
                        <Modal.Title><FaIcon type="solid" icon="plus" /> Új kapcsolat létrehozása</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>                    
                        <Form.Group className="mb-3" controlId="qsoForm.myqth">
                            <Form.Label>Ellenállomás QTH kódja</Form.Label>
                            <Form.Control type="text" name="qth" onChange={handleQTHChange} value={state.qth.qth !== undefined ? state.qth.qth : ''}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="qsoForm.myqth">
                            <Form.Label>Dátum</Form.Label>
                            <Form.Control type="datetime-local" name="date" onChange={handleQTHChange} value={state.qth.date}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="qsoForm.myqth">
                            <Row>
                                <Col>
                                    <Form.Label>Hívójel</Form.Label>
                                    <Form.Control type="text" name="callsign" onChange={handleQTHChange} value={state.qth.callsign}/>
                                </Col>
                                <Col>
                                    <Form.Label>Operátornév</Form.Label>
                                    <Form.Control type="text" name="opName" onChange={handleQTHChange} value={state.qth.opName}/>
                                </Col>
                            </Row>                            
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="qsoForm.myqth">
                            <Row>
                                <Col>
                                    <Form.Label>Frekvencia</Form.Label>
                                    <InputGroup>
                                        <Form.Control type="text" name="freq" onChange={handleQTHChange} value={state.qth.freq}/>
                                        <InputGroup.Text>MHz</InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label>CT/DCS</Form.Label>
                                    <Form.Control type="text" name="ctcs" onChange={handleQTHChange} value={state.qth.ctcs}/>
                                </Col>
                            </Row>                            
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="qsoForm.myqth">
                            <Row>
                                <Col>
                                    <Form.Label>Típus</Form.Label>
                                    <Form.Select name="type" onChange={handleQTHChange}>
                                        <option value="/">Stabil</option>
                                        <option value="/P">Kitelepült</option>
                                        <option value="/M">Mobil</option>
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Label>Módja</Form.Label>
                                    <Form.Select name="mode" onChange={handleQTHChange}>
                                        <option value="/D">Közvetlen</option>
                                        <option value="/P">Papagájon át</option>
                                        <option value="/A">Átjátszón át</option>
                                    </Form.Select>
                                </Col>
                            </Row>                            
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup>
                            <Button variant="danger" onClick={() => {setFormShow(false);}}><FaIcon type="solid" icon="times"/> Mégse</Button>
                            <Button variant="success" type="submit"><FaIcon type="solid" icon="save"/> Mentés</Button>
                        </ButtonGroup>
                    </Modal.Footer>
                </Form>
            </Modal>
            {state.message && (<Alert variant="success">{state.message}</Alert>)}
            <Form onSubmit={submitForm}>
                <Form.Group className="mb-3" controlId="qsoForm.myqth">
                    <Form.Label>QTH lokátor</Form.Label>
                    <Form.Control type="text" name="myQth" maxLength={6} className={qthCssClass} onChange={handleChange} value={state.user.myQth}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="qsoForm.myqth">
                    <Form.Label>Hívójel</Form.Label>
                    <Form.Control type="text" name="myName" onChange={handleChange} value={state.user.myName}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Button type="button" onClick={() => {setFormShow(true);}}><FaIcon type="solid" icon="plus"/> Új kapcsolat</Button>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Accordion>
                        {state.qthList.map((qth, index) => (
                            <Accordion.Item eventKey={index} key={index}>
                                <Accordion.Header>
                                    <FaIcon type="solid" icon="user" />&nbsp;&nbsp;{qth.callsign} |&nbsp;
                                    {qth.freq} MHz, CT/DCS: {qth.ctcs}
                                </Accordion.Header>
                                <Accordion.Body>
                                    QTH: <b>{qth.qth}</b>, Távolság: <b>{getDistanceByQTH(state.user.myQth, qth.qth).toFixed(2)} km</b><br/>
                                    Időpont: <b>{qth.date}</b><br/>
                                    Típus: <b>{qth.type}</b>, Módja: <b>{qth.mode}</b>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Button type="submit">Mentés</Button>
                </Form.Group>
            </Form>
        </Container>
    );
};

export default MobileQSOSend;