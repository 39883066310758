import { MDBBadge, MDBCol, MDBListGroup, MDBListGroupItem, MDBRow } from 'mdb-react-ui-kit';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import client from '../../Utils/apiClient';
import MyStorage from '../../Utils/MyStorage';
import FaIcon from '../../Global/Widgets/FaIcon';
import { getCoordsByMaidenhead, getDistanceByQTH } from '../../Utils/LocationService';
import { MapContainer, Polyline, TileLayer, useMap, Tooltip } from 'react-leaflet';
import MarkerCombined from '../../Global/Components/Terkep/MarkerCombined';
import { formatCTCSSorDCSCode, formatDate, formatFrequency, formatQSOMode, formatQSOType } from '../../Utils/Formatter';
const FitMapToBounds = ({ pointA, pointB }) => { const map = useMap(); useEffect(() => { const bounds = [pointA, pointB]; map.fitBounds(bounds, {padding: [5,5]}); }, [pointA, pointB, map]); }

const MobileQSODetails = () => {
    const { id } = useParams();
    const mapRef = useRef();
    const [result, setResult] = useState(false);
    const [center, setCenter] = useState([47.1628, 19.5036]);
    const [zoom, setZoom] = useState(6);

    useEffect(() => {
        const user = MyStorage.local.get('user');
        client.post('getbyid', { callsign: user.displayName, id: id }, (data) => {
            
            if (!data.success) {
                setResult({ success: false, error: data.message });
            } else {
                
                setResult({ success: true, item: data.item });
                const myPos = getCoordsByMaidenhead(result?.item.my_qth);
                const remPos = getCoordsByMaidenhead(result?.item.rem_qth);
                let _center = [ ((myPos.lat + remPos.lat) / 2), ((myPos.lon + remPos.lon) / 2) ];
                setCenter(_center);
            };
        }, MyStorage.local.get('token'));
       
    }, []);

    return (
        <Container className="mobileContainer">
            <MDBListGroup>
                {(result?.success === false) && (
                    <>
                        <MDBListGroupItem noBorders color="danger" className="px-3 mb-2 rounded-3">
                            <b>Hiba</b><br />
                            {result?.error}
                        </MDBListGroupItem>
                        <MDBListGroupItem noBorders className="px-3 mb-2 rounded-3">
                            <FaIcon type="sharp fa-light" icon="circle-left" /> Vissza
                            <Link to="/qso_list" className="stretched-link"></Link>
                        </MDBListGroupItem>
                    </>
                )}
                {(result?.success === true) && (
                    <>
                        <MDBListGroupItem noBorders>
                            <MDBRow>
                                <MDBCol size={8}>
                                    <h4>Ellenállomás</h4>
                                </MDBCol>
                                <MDBCol size={4} className="text-right">
                                    {(!result.item.approvedAt && !result.item.rejectedAt) && (<MDBBadge className="ms-2 bg-warning text-bg-warning">Függőben</MDBBadge>)}
                                    {(!result.item.approvedAt && result.item.rejectedAt) && (<MDBBadge className="ms-2 bg-danger text-bg-danger">Elutasítva</MDBBadge>)}
                                    {(result.item.approvedAt && !result.item.rejectedAt) && (<MDBBadge className="ms-2 bg-success text-bg-success">Visszaigazolva</MDBBadge>)}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mb-3">
                                <MDBCol size={6}>
                                    Hívójel: <b>{result?.item.rem_callsign}</b><br/>
                                    Operátor: <b>{result?.item.rem_opName}</b>
                                </MDBCol>
                                <MDBCol size={6} className="text-right">
                                    <b>{formatFrequency(result?.item.freq)}</b><br/>
                                    <i>{formatCTCSSorDCSCode(result?.item.ctcs)}</i>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size={12}>
                                    <h4>Összeköttetés</h4>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size={6}>
                                    Típusa: <b>{formatQSOType(result?.item.type)}</b>
                                </MDBCol>
                                <MDBCol size={6} className="text-right">
                                    Módja: <b>{formatQSOMode(result?.item.mode)}</b>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='mb-3'>
                                <MDBCol size={8}>
                                    Beküldve: <b>{formatDate(new Date(result?.item.createdAt), ".")}</b>
                                </MDBCol>
                                
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size={4}>
                                    Lokátorom: <b>{result?.item.my_qth}</b>
                                </MDBCol>
                                <MDBCol size={4} className="text-right">
                                    Ellen lokátor: <b>{result?.item.rem_qth}</b>
                                </MDBCol>
                                <MDBCol size={4} className="text-right">
                                    Távolság: <b>{getDistanceByQTH(result?.item.my_qth, result?.item.rem_qth).toFixed(2)} km</b>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size={12}>
                                    <MapContainer 
                                        ref={mapRef} 
                                        center={center} 
                                        zoom={zoom} 
                                        scrollWheelZoom={true} 
                                        style={{ 
                                            width: 'clamp(250px, 100vw, 360px)', 
                                            height: 'clamp(300px, 100vh, 480px)'
                                        }}
                                    >
                                        <TileLayer 
                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' 
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
                                        />
                                        <MarkerCombined 
                                            key={0} 
                                            args={{
                                                id: 0,
                                                type: 'handradio',
                                                lat: getCoordsByMaidenhead(result?.item.my_qth).lat,
                                                lon: getCoordsByMaidenhead(result?.item.my_qth).lon,
                                                title: result?.item.my_callsign,
                                                descriotion: ''
                                            }} 
                                            eventAction={() => {}} 
                                            callback={() => {}} 
                                        />
                                        <MarkerCombined 
                                            key={1} 
                                            args={{
                                                id: 1,
                                                type: 'handradio',
                                                lat: getCoordsByMaidenhead(result?.item.rem_qth).lat,
                                                lon: getCoordsByMaidenhead(result?.item.rem_qth).lon,
                                                title: result?.item.rem_callsign,
                                                descriotion: ''
                                            }} 
                                            eventAction={() => {}} 
                                            callback={() => {}} 
                                        />
                                        <FitMapToBounds 
                                            pointA={[
                                                getCoordsByMaidenhead(result?.item.my_qth).lat,
                                                getCoordsByMaidenhead(result?.item.my_qth).lon
                                            ]}
                                            pointB={[
                                                getCoordsByMaidenhead(result?.item.rem_qth).lat,
                                                getCoordsByMaidenhead(result?.item.rem_qth).lon
                                            ]}
                                        />
                                        <Polyline 
                                            pathOptions={{ color: 'red' }} 
                                            positions={[
                                                [getCoordsByMaidenhead(result?.item.my_qth).lat, getCoordsByMaidenhead(result?.item.my_qth).lon],
                                                [getCoordsByMaidenhead(result?.item.rem_qth).lat, getCoordsByMaidenhead(result?.item.rem_qth).lon]
                                            ]}
                                        >
                                            <Tooltip 
                                                permanent 
                                                direction="top" 
                                                offset={[0,0]} 
                                                opacity={1}
                                            >
                                                {getDistanceByQTH(result?.item.my_qth, result?.item.rem_qth).toFixed(2)} km
                                            </Tooltip>
                                        </Polyline>
                                    </MapContainer>
                                </MDBCol>
                            </MDBRow>
                        </MDBListGroupItem>
                    </>
                )}
            </MDBListGroup>
        </Container>
    );
};

export default MobileQSODetails;