import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, useMapEvent, LayersControl } from 'react-leaflet';
import client from '../../Utils/apiClient';
import MyStorage from '../../Utils/MyStorage';
import MarkerCombined from './Terkep/MarkerCombined';
import MapCTXMenu from './Terkep/MapCTXMenu';
import HandradioModal from './Terkep/Modals/HandradioModal';
import { isDesktop, isMobile } from 'react-device-detect';
import DeskradioModal from './Terkep/Modals/DeskradioModal';
import ParrotModal from './Terkep/Modals/ParrotModal';
import TowerModal from './Terkep/Modals/TowerModal';

const Terkep = ({ zoom }) => {
    const { BaseLayer } = LayersControl;

    const [markers, setMarkers] = useState([]);
    const [contextmenu, setContextMenu] = useState({
        top: '0px',
        left: '0px',
        display: 'none',
        show: false,
        coords: { lat: 0.0, lon: 0.0 }
    });
    const [handRadio, setHandRadio] = useState(false);
    const [deskRadio, setDeskRadio] = useState(false);
    const [parrot, setParrot] = useState(false);
    const [tower, setTower] = useState(false);
    
    const fetchMarkers = () => { client.get('markers',(data) => { setMarkers(data); }, MyStorage.local.get('token')); }

    useEffect(() => {
        fetchMarkers();
        document.getElementById("map").focus();
        const handleContextMenu = (event) => { event.preventDefault(); };
        document.addEventListener('contextmenu', handleContextMenu);
        return () => { document.removeEventListener('contextmenu', handleContextMenu); };
    },[]);

    const MapEvents = () => {
        useMapEvent('click', (e) => {
            if(isMobile && contextmenu.display === "none"){
                setContextMenu({top: e.containerPoint.y, left: e.containerPoint.x,display: 'block', coords: { lat: e.latlng.lat, lon: e.latlng.lng}});
            }else{
                setContextMenu({top: 0, left: 0, display: 'none', coords: { lat: 0.0, lon: 0.0}});
            }
        });
        useMapEvent('contextmenu', (e) => {
            setContextMenu({top: e.containerPoint.y, left: e.containerPoint.x,display: 'block', coords: { lat: e.latlng.lat, lon: e.latlng.lng}});
        })
        return null;
    }

    const eventAction = (item) => {
        setContextMenu({top: 0, left: 0, display: 'none', coords: contextmenu.coords});
        switch(item){
            case "handradio": setHandRadio(true); break;
            case "deskradio": setDeskRadio(true); break;
            case "parrot": setParrot(true); break;
            case "tower": setTower(true); break;
            case "markerContextMenu": break;
            default: break;
        };
    }
    const eventCallback = (item) => {
        setContextMenu({top: 0, left: 0, display: 'none', coords: {lat: 0.0, lon: 0.0}});
        switch(item){
            case "handradio": fetchMarkers(); setHandRadio(false); break;
            case "deskradio": fetchMarkers(); setDeskRadio(false); break;
            case "parrot": fetchMarkers(); setParrot(false); break;
            case "tower": fetchMarkers(); setTower(false); break;
            default: break;
        };
        
    }

    return (
        <>
            {(isMobile && (
            <MapContainer id="map" tabIndex={0} center={[47.1628, 19.5036]} zoom={zoom} scrollWheelZoom={true} style={{ width: '100vw', height: '100vh'}}>
                <TileLayer attribution='' url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <MapEvents />
                {markers.map(marker => (<MarkerCombined key={marker.id} args={marker} eventAction={(item) => eventAction(item)} callback={(item) => eventCallback(item)} />))}                
            </MapContainer>
            ))}


            {(isDesktop && (
            <MapContainer id="map" tabIndex={0} center={[47.1628, 19.5036]} zoom={zoom} scrollWheelZoom={true} style={{ width: '100vw', height: 'calc(100vh - 56px)'}}>
                <LayersControl>
                    <BaseLayer checked name="OpenSteetMap">
                        <TileLayer attribution='' url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    </BaseLayer>
                    <BaseLayer name="Hybrid">
                        <TileLayer attribution='' url="https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png" />
                    </BaseLayer>
                </LayersControl>
                <MapEvents />
                {markers.map(marker => (<MarkerCombined key={marker.id} args={marker} eventAction={(item) => eventAction(item)} callback={(item) => eventCallback(item)} />))}                
            </MapContainer>
            ))}


            <MapCTXMenu pos={{top: contextmenu.top, left: contextmenu.left, display: contextmenu.display}} eventAction={(item) => eventAction(item)}/>
            <HandradioModal show={handRadio} pos={{ lat: contextmenu.coords.lat, lon: contextmenu.coords.lon }} callback={(item) => eventCallback(item)}/>
            <DeskradioModal show={deskRadio} pos={{ lat: contextmenu.coords.lat, lon: contextmenu.coords.lon }} callback={(item) => eventCallback(item)}/>
            <ParrotModal show={parrot} pos={{ lat: contextmenu.coords.lat, lon: contextmenu.coords.lon }} callback={(item) => eventCallback(item)}/>
            <TowerModal show={tower} pos={{ lat: contextmenu.coords.lat, lon: contextmenu.coords.lon }} callback={(item) => eventCallback(item)}/>
        </>
    );
};

export default Terkep;