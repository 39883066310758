import { Editor } from '@tinymce/tinymce-react';
import React, { useRef, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import client from '../../../../Utils/apiClient';
import MyStorage from '../../../../Utils/MyStorage';

const HandradioModal = ({ show, pos, callback}) => {
    const editorRef = useRef(null);
    const [state, setState] = useState({
        fields: {
            markerTitle: {
                value: '',
                error: '',
                css: 'is-invalid'
            }
        }
    });

    const handleChange = (e) => {
        const field = e.target.name;
        let fields = state.fields;
        fields[field]['value'] = e.target.value;
        fields[field]['css'] = (e.target.value.length >= 6) ? 'is-valid' : 'is-invalid';
        setState({...state, fields});
    }

    const eventSubmit = (e) => {
        e.preventDefault();
        const fields = {
            type: 'handradio',
            lat: pos.lat,
            lon: pos.lon,
            title: state.fields.markerTitle.value,
            description: editorRef.current.getContent(),
            isActive: true,
        };
        client.post('markerAdd', fields, () => {
            let f = state.fields;
            f['markerTitle']['value'] = '';
            f['markerTitle']['css'] = 'is-invalid';
            setState({...state, f});
            callback('handradio');
        }, MyStorage.local.get('token'));
    }

    return (
        <Modal show={show}>
            <Form onSubmit={(e) => { eventSubmit(e) }}>
                <Modal.Header>
                    <Modal.Title>Új kézi rádió marker létrehozása</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Control className={state.fields.markerTitle.css} type="text" name="markerTitle" id="markerTitle" value={state.fields.markerTitle.value} onChange={handleChange} placeholder="Marker neve" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Editor apiKey="ihuzhtbtyuzbwks4d8nuyl9bmu1uq25scfmxd8gjgmmqc5qz" onInit={(_evt, editor) => editorRef.current = editor} init={{ menubar: false, toolbar: 'undo redo | bold italic' }} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { callback('handradio'); }} varian="danger">Mégse</Button>
                    <Button type="submit" variant="success">Mentés</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default HandradioModal;