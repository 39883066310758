import React, { useEffect, useState } from 'react';
import FaIcon from '../../Global/Widgets/FaIcon';
import { MDBTooltip } from 'mdb-react-ui-kit';

const ToolTipText = ({ title, arr }) => {
    return (
        <span key={title}>
            <b>{title}</b><br />
            {(arr.slice(0, 10).map((item) => (<>{item.username}<br /></>)))}
        </span>
    )
}

const ToolTip = ({ reactions, iconType, icon, style, title, arr }) => {
    return (
        <MDBTooltip tag='span' title={<ToolTipText title={title} arr={arr} />}>
            <FaIcon type={iconType} icon={icon} style={style} />
        </MDBTooltip>
    )
}

const ProfileLikeBox = ({ _likes, count }) => {
    const [reactions, setReactions] = useState({
        likes: [],
        loves: [],
        sads: [],
        hates: []
    });
    const [haveLike, setHaveLike] = useState(false);
    const [haveLove, setHaveLove] = useState(false);
    const [haveSad, setHaveSad] = useState(false);
    const [haveAngry, setHaveAngry] = useState(false);

    useEffect(() => {
        _likes.forEach(like => {
            switch (like.reaction) {
                case 'like':
                    if (!reactions.likes.some(exists => exists.id === like.id)) {
                        setHaveLike(true);
                        setReactions(prev => ({ ...prev, likes: [...prev.likes, like] }))
                    };
                    break;
                case 'love':
                    if (!reactions.loves.some(exists => exists.id === like.id)) {
                        setHaveLove(true);
                        setReactions(prev => ({ ...prev, loves: [...prev.loves, like] }))
                    };
                    break;
                case 'sad':
                    if (!reactions.sads.some(exists => exists.id === like.id)) {
                        setHaveSad(true);
                        setReactions(prev => ({ ...prev, sads: [...prev.sads, like] }))
                    };                    
                    break;
                case 'hate':
                    if (!reactions.hates.some(exists => exists.id === like.id)) {
                        setHaveAngry(true);
                        setReactions(prev => ({ ...prev, hates: [...prev.hates, like] }))
                    };
                    break;
                default: break;
            };
        });
    }, []);

    return (
        <>
            {(haveLike && (<ToolTip reactions={reactions} iconType="regular" icon="thumbs-up" title="Kedvelem" arr={reactions.likes} style={{ color: '#00b322' }}></ToolTip>))}
            {(haveLove && (<ToolTip reactions={reactions} iconType="regular" icon="heart" title="Imádom" arr={reactions.loves} style={{ color: '#8f0000' }}></ToolTip>))}
            {(haveSad && (<ToolTip reactions={reactions} iconType="regular" icon="face-sad-sweat" title="Szomorú" arr={reactions.sads} style={{ color: '#dbef00' }}></ToolTip>))}
            {(haveAngry && (<ToolTip reactions={reactions} iconType="solid" icon="face-swear" title="Dühítő" arr={reactions.hates} style={{ color: '#ff0000' }}></ToolTip>))}
            {(count > 0 && <span style={{ paddingLeft: '10px' }}>{count}</span>)}
        </>
    );
};

export default ProfileLikeBox;