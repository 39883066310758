import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import MobileApp from './MobileComponents/App';
import MyStorage from './Utils/MyStorage';
import client from './Utils/apiClient';
import DesktopApp from './DesktopComponents/App';


function App() {
  let events = ['click','scroll'];

  const [inited, setInited] = useState(false);
  
  const createAuth = async () => {
    return new Promise((resolve, reject) => {
      client.post('auth', {}, (data) => {
        if (data.success) {
          MyStorage.local.put('token', data.token);
          resolve();
        } else {
          reject();
        }
      });
    });
  }
  const getUserFields = async () => {
    const _auth = Cookies.get('auth');
    if(_auth === undefined){
      MyStorage.local.remove('token');
      MyStorage.local.remove('user');
      window.location.replace("http://auth.446.hu"); 
      return;
    }else{
      await createAuth().then((_data) => {
        const bytes = CryptoJS.AES.decrypt(_auth, "446.hu#aA123456&#&");
        const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return new Promise((resolve, reject) => {
          client.post('getMy', { id: data.uid }, (user) => {
            if(data.success){          
              MyStorage.local.put('user', user);
              setInited(true);
              resolve(user);
            }else{ 
              console.error(data);
              reject("Hiba történt!"); 
            };
          }, MyStorage.local.get('token'));
        });
      });
    }
  }
  const updateActivity = async () => {
    let uid = MyStorage.local.get('user').uid;
    client.background('post','/onlineUsers', {uid: uid}, () => {}, MyStorage.local.get('token'));
  }

  useEffect(() => {
    getUserFields();
    
    
    (events.forEach((event) => { window.addEventListener(event, updateActivity); }));

    return () => { events.forEach((event) => window.removeEventListener(event, updateActivity)); };

  }, []);



  return (
    (inited && (
    <>
      <BrowserView>
        <DesktopApp />
      </BrowserView>
      <MobileView>
        <MobileApp />
      </MobileView>
    </>
    ))
  );
}

export default App;
