import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import client from '../../../Utils/apiClient';
import MyStorage from '../../../Utils/MyStorage';
import { Alert, Button, ButtonGroup, Col, Form, InputGroup, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import FaIcon from '../../../Global/Widgets/FaIcon';
import NavbarUserPicture from '../../Widgets/NavbarWidgets/NavbarUserPicture';

const DesktopDownloadShowFile = () => {
    const location = useLocation();
    const alias = location.pathname.split('/').slice(-1)[0];
    const uid = MyStorage.local.get('user').uid;
    const perm = MyStorage.local.get('user').perm;
    const [file, setFile] = useState(false);
    const [comments, setComments] = useState([]);
    const [myComment, setMyComment] = useState('');
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [brokenAlert, setBrokenAlert] = useState(false);
    const [brokenText, setBrokenText] = useState('');

    const checkBrokenLink = (id) => {
        client.post('/checkBrokenLink', {file_id: id}, (data) => {
            if(data.success === true){
                setBrokenAlert(true);
                setBrokenText(`A letöltés hibásnak lett jelölve ${data.count} alkalommal, de már dolgozunk az ügyön! Kérlek, fenntartásokkal kezeld!`);
            };
        },MyStorage.local.get('token'));
    }
    const reportBrokenLink = () => {
        client.post('/reportBrokenLink', {file_id: file.id, uid: uid}, () => {
            checkBrokenLink(file.id);
        },MyStorage.local.get('token'));
    }
    const handleChange = (event) => { setSubmitDisabled((event.target.value.length > 0) ? false : true); setMyComment(event.target.value); }
    const createComment = (event) => {
        event.preventDefault();
        client.post('/createComment', {file_id: file.id, uid: uid, comment: myComment}, (data) => {
            setMyComment('');
            setSubmitDisabled(true);
            getComments(file.id);
        },MyStorage.local.get('token'));
    }

    const getFileDetails = async () => {
        await client.post('/getDlFileByAlias', { alias: alias }, (data) => {
            setFile(data.files);
            getComments(data.files.id);
            checkBrokenLink(data.files.id);
        }, MyStorage.local.get('token'));
    }
    const getComments = async (fileID) => {
        await client.post('/getFileComments', { file_id: fileID }, (comments) => {
            setComments(comments.comments);
        }, MyStorage.local.get('token'));
    }

    /*Admin funcs*/
    const [ctxVisible, setCtxVisible] = useState(false);
    const [ctxPos, setCtxPos] = useState({ x: 0, y: 0});
    const [ctxId, setCtxId] = useState(-1);
    const showCtx = (id, event) => {
        event.preventDefault();
        const { right, top } = event.target.getBoundingClientRect();
        setCtxPos({x : right, y: top});
        setCtxId(id);
        setCtxVisible(true);
    }
    const hideCtx = () => {
        setCtxPos({x : 0, y: 0});
        setCtxId(-1);
        setCtxVisible(false);
    }
    const adminMenuItemClick = (item, id) => {
        switch(item){
            case "removeComment": 
                client.post('/removeComment', {id: id}, () => getComments(file.id), MyStorage.local.get('token'));
                hideCtx();
                break;
            default: break;
        };
    }       

    useEffect(() => { 
        getFileDetails();
    }, []);

    return (
        <>
            {ctxVisible && (
                <div 
                    style={{
                        position: 'absolute',
                        top: ctxPos.y,
                        left: ctxPos.x,
                        backgroundColor: 'white',
                        color: '#000000',
                        porder: '1px solid #ccc',
                        padding: '10px',
                        zIndex: 1000
                    }}
                >
                    <span style={{position:'absolute',top:'0px',right:'10px',cursor:'pointer'}} onClick={() => hideCtx()}>X</span>
                    <ul style={{listStyleType:'none', paddingTop:'15px'}}>
                        <li style={{cursor:'pointer', position:'relative', left:'-20px'}} onClick={() => adminMenuItemClick('removeComment', ctxId)}><FaIcon type="solid" icon="trash" /> Komment törlése</li>
                    </ul>
                </div>
            )}
            {(brokenAlert) && (<Alert variant='danger'>{brokenText}</Alert>)}
            <Row className="fb-styled-card">
                <Col md={2} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <img src={file.image} alt="Thumbnail" style={{ width: '100%' }} />
                </Col>
                <Col md={6} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Row>
                        <Col>
                            <h3>{file.name}</h3>
                            <p>{file.description}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ListGroup className="fb-style-list">
                                <ListGroupItem>
                                    <Row>
                                        <Col>
                                            <b>Hozzászólások</b>
                                            <Form onSubmit={createComment}>
                                                <InputGroup>
                                                    <Form.Control type="text" name="comment" placeholder="Mit gondolsz erről?" value={myComment} onChange={handleChange} />
                                                    <Button type="submit" disabled={submitDisabled} variant="fb-field-button"><FaIcon type="solid" icon="paper-plane-top" /></Button>
                                                </InputGroup>
                                            </Form>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                                {(comments.sort((a,b) => (new Date(b.createdAt) - new Date(a.createdAt))).map((item, index) => (
                                    <ListGroupItem>
                                        <Row>
                                            <Col md={2}>
                                                <NavbarUserPicture user={{ avatar: item.avatar }} showName={false} w={45} h={45} />
                                            </Col>
                                            <Col md={9}>
                                                <b>{item.callsign}</b><br/>
                                                <span>{item.comment}</span>
                                            </Col>
                                            <Col md={1}>
                                                {(perm >= 97) && (<span style={{cursor:'pointer'}} onClick={(event) => { showCtx(item.id, event); }}><FaIcon type="solid" icon="ellipsis" /></span>)}
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                )))}
                            </ListGroup>
                        </Col>
                    </Row>
                </Col>
                <Col md={4} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <ButtonGroup className="mb-3">
                        <Button className="d-block" variant="fb-field-button" target='_blank' as={Link} to={`/getFile/${file.alias}`}>
                            <FaIcon type="solid" icon="download" /> Letöltés
                        </Button>
                        <Button className="d-block" variant="fb-field-button" onClick={reportBrokenLink}>
                            <FaIcon type="solid" icon="flag" style={{ color: '#ff0000' }} /> Hibás link
                        </Button>
                    </ButtonGroup>
                    <ListGroup>
                        <ListGroupItem>Letöltve: <b>{file.downloaded}</b> alkalommal</ListGroupItem>
                        <ListGroupItem>Publikálva: <b>{file.createdAt}</b></ListGroupItem>
                        {(file.updatedAt) && (<ListGroupItem>Módosítva: <b>{file.updatedAt}</b></ListGroupItem>)}
                    </ListGroup>
                </Col>
            </Row>
        </>
    );
};

export default DesktopDownloadShowFile;