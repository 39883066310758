import { MDBBadge, MDBCol, MDBListGroup, MDBListGroupItem, MDBRow } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import client from '../../Utils/apiClient';
import MyStorage from '../../Utils/MyStorage';
import { Container } from 'react-bootstrap';
import { getDistanceByQTH } from '../../Utils/LocationService';
import { Link } from 'react-router-dom';
import FaIcon from '../../Global/Widgets/FaIcon';
import { formatCTCSSorDCSCode, formatDate, formatFrequency, formatQSOMode, formatQSOType } from '../../Utils/Formatter';

const MobileQSOLogList = () => {
    const [state, setState] = useState({
        list: []
    })

    useEffect(() => {
        let user = MyStorage.local.get('user');
        client.post('getmyqths', { callsign: user.displayName }, (data) => {
            let list = [];
            data.items.forEach((item) => { list.push(item); });
            setState({ ...state, list });
        }, MyStorage.local.get('token'));
    }, []);

    return (
        <Container className="mobileContainer">
            <MDBListGroup>
                {state.list
                    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                    .slice(0, 20)
                    .map((item, index) => (
                        <MDBListGroupItem key={index} className="d-block justify-content-between align-items-start">
                            <MDBRow>
                                <MDBCol className="ms-auto" size={9}>
                                    <div className="fw-bold">{item.rem_callsign} <i>({item.rem_opName})</i></div>
                                    <i>{formatDate(new Date(item.createdAt), ".")}</i>
                                </MDBCol>
                                <MDBCol className="me-auto text-right" size={3}>
                                    <MDBBadge pill light><FaIcon type="solid" icon="ruler" />{`${getDistanceByQTH(item.my_qth, item.rem_qth).toFixed(2)} km`}</MDBBadge>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size={7}>
                                    Frekvencia: <b>{formatFrequency(item.freq)}</b><br/>
                                    Típus: <b>{formatQSOType(item.type)}</b> | Mód: <b>{formatQSOMode(item.mode)}</b>
                                </MDBCol>
                                <MDBCol size={5} className="text-right">
                                    {item.ctcs && (formatCTCSSorDCSCode(item.ctcs))}<br/>
                                    {(item.approvedAt) && (<><FaIcon type="solid" icon="check" style={{color: 'green'}}/> <span style={{color: 'green'}}>Visszaigazolva</span></>)}
                                    {(!item.approvedAt && !item.rejectedAt) && (<><FaIcon type="solid" icon="hourglass" style={{color: '#b08d00'}}/> <span style={{color: '#b08d00'}}>Függőben</span></>)}
                                    {(!item.approvedAt && item.rejectedAt) && (<><FaIcon type="solid" icon="times" style={{color: 'red'}}/> <span style={{color: 'red'}}>Elutasítva</span></>)}
                                    <Link to={`/qso/${item.id}`} className="stretched-link"></Link>
                                </MDBCol>
                            </MDBRow>
                        </MDBListGroupItem>
                    ))
                }
            </MDBListGroup>
        </Container>
    );
};

export default MobileQSOLogList;