import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import React, { startTransition, useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { MapContainer, Polyline, TileLayer } from 'react-leaflet';
import FaIcon from '../../../Global/Widgets/FaIcon';
import { formatDate, formatDateForInput } from '../../../Utils/Formatter';
import client from '../../../Utils/apiClient';
import MyStorage from '../../../Utils/MyStorage';
import MarkerCombined from '../../../Global/Components/Terkep/MarkerCombined';
import { getCoordsByMaidenhead } from '../../../Utils/LocationService';

const QSOMap = ({ user }) => {
    const mapRef = useRef(false);
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [hasFilter, setHasFilter] = useState(false);
    const [qso, setQSO] = useState([]);

    const setDefaultFilter = () => {
        let end = new Date();
        let start = new Date();
        setTo(end.toLocaleDateString());
        start.setMonth(end.getMonth() - 1);
        setFrom(start.toLocaleDateString());
    }

    useEffect(() => {
        setDefaultFilter();
        getQSOInInterval();
    }, []);

    const setPredefinedPresets = (selected) => {
        let args = selected.split(':');
        let end = new Date();
        let start = new Date();
        setTo(end.toLocaleDateString());
        switch (args[0]) {
            case 'day': start.setDate(end.getDate() - args[1]); break;
            case 'month': start.setMonth(end.getMonth() - args[1]); break;
            case 'custom': document.getElementById('date_from').focus(); break;
            default: break;
        };
        setFrom(start.toLocaleDateString());

    }

    const handleChange = (event) => {
        switch (event.target.name) {
            case "predefined":
                setPredefinedPresets(event.target.value);
                break;
            case "from":
                setFrom(event.target.value);
                break;
            case "to":
                setTo(event.target.value);
                break;
            default: break;
        }
    }

    const getQSOInInterval = () => {
        let filter = {
            callsign: user.displayName,
            from: formatDate(new Date(from), '-'),
            to: formatDate(new Date(to), '-'),
        };
        client.post('/getInInterval', filter, (data) => {
            let items = [];
            data.items.forEach((item) => {
                items.push(item);
            });
            setQSO(items);
        }, MyStorage.local.get('token'));
    }

    const filterDate = (event) => {
        event.preventDefault();
        getQSOInInterval();


    }

    return (
        <>
            <MDBRow>
                <MDBCol size={12} className="me-auto">
                    <Form onSubmit={filterDate}>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1"><FaIcon type="solid" icon="search" /></InputGroup.Text>
                            <Form.Control type="date" id="date_from" value={formatDateForInput(from)} max={formatDateForInput(new Date())} onChange={handleChange} placeholder="Mettől" name="from" />
                            <Form.Control type="date" id="date_to" value={formatDateForInput(to)} max={formatDateForInput(new Date())} onChange={handleChange} placeholder="Meddig" name="to" />
                            <Form.Select name="predefined" onChange={handleChange}>
                                <option value='day:0'>Ma</option>
                                <option value='day:1'>Tegnap</option>
                                <option value='day:2'>2 nap</option>
                                <option value='day:5'>5 nap</option>
                                <option value='day:7'>1 hét</option>
                                <option value='day:14'>2 hét</option>
                                <option selected value='month:1'>1 hónap</option>
                                <option value='month:3'>3 hónap</option>
                                <option value='month:6'>6 hónap</option>
                                <option value='month:12'>12 hónap</option>
                                <option value='custom:0'>Egyedi</option>
                            </Form.Select>
                            <Button type="submit" variant="primary"><FaIcon type="solid" icon="search" /> Szűrés</Button>
                            {(hasFilter && (<Button type="submit" variant="danger"><FaIcon type="solid" icon="times" /> Törlés</Button>))}
                        </InputGroup>
                    </Form>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol size={12}>
                    <MapContainer
                        ref={mapRef}
                        center={[47.1628, 19.5036]}
                        zoom={7}
                        scrollWheelZoom={false}
                        dragging={false}
                        doubleClickZoom={false}
                        zoomControl={false}
                        style={{
                            height: "clamp(600px, 50%, 725px)"
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0,0,0,0.2)',
                                zIndex: 1000, // Magas z-index, hogy a térkép fölött legyen
                            }}
                        ></div>
                        <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        {(qso.map((item, index) => (<QSOItem item={item} id={index} key={index} />)))}
                    </MapContainer>
                </MDBCol>
            </MDBRow>
        </>
    );
};

const QSOItem = ({ item, id }) => {
    console.log(item);

    const pointA = {
        title: item.my_callsign,
        lat: getCoordsByMaidenhead(item.my_qth).lat,
        lon: getCoordsByMaidenhead(item.my_qth).lon,
        icon: 'handradio'
    }
    const pointB = {
        title: item.rem_callsign,
        lat: getCoordsByMaidenhead(item.rem_qth).lat,
        lon: getCoordsByMaidenhead(item.rem_qth).lon,
        icon: 'handradio'
    }
    let pointC = null;
    if (item.pID !== null) {
        pointC = {
            title: item.title,
            lat: item.lat,
            lon: item.lon,
            icon: item.markerType
        };
    };

    return (
        <>
            <MarkerCombined
                key={0}
                args={{
                    id: 0,
                    type: pointA.icon,
                    lat: pointA.lat,
                    lon: pointA.lon,
                    title: pointA.title,
                    description: '<i>Ez a funkció itt nem érhető el!</i>'
                }}
                eventAction={() => { }}
                callback={() => { }}
            />
            <MarkerCombined
                key={1}
                args={{
                    id: 1,
                    type: pointB.icon,
                    lat: pointB.lat,
                    lon: pointB.lon,
                    title: pointB.title,
                    description: '<i>Ez a funkció itt nem érhető el!</i>'
                }}
                eventAction={() => { }}
                callback={() => { }}
            />
            {(pointC != null) && (
                <MarkerCombined
                    key={2}
                    args={{
                        id: 0,
                        type: pointC.icon,
                        lat: pointC.lat,
                        lon: pointC.lon,
                        title: pointC.title,
                        description: '<i>Ez a funkció itt nem érhető el!</i>'
                    }}
                    eventAction={() => { }}
                    callback={() => { }}
                />
            )}
            <Polyline
                pathOptions={{ color: 'red' }}
                positions={[
                    [pointA.lat, pointA.lon],
                    ...(pointC ? [[pointC.lat, pointC.lon]] : []),
                    [pointB.lat, pointB.lon]
                ]}
            >
            </Polyline>
        </>
    );
}

export default QSOMap;