import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import NavbarUserPicture from './NavbarUserPicture';
import { Link } from 'react-router-dom';
import FaIcon from '../../../Global/Widgets/FaIcon';

const NavbarUserDropdown = ({ user }) => {
    return (
        <NavDropdown className="user-dropdown" title={<NavbarUserPicture user={user} showName={false} />} id="navbarScrollingDropdown">
            <NavDropdown.Item as={Link} to={`/profile`}>
                <NavbarUserPicture user={user} showName={true} />
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to={`/logout`}>
                <FaIcon type="solid" icon="sign-out-alt" /> Kijelentkezés
            </NavDropdown.Item>
        </NavDropdown>
    );
};

export default NavbarUserDropdown;