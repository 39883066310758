import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NavbarBrand = () => {
    return (
        <Navbar.Brand as={Link} to="/">
            <img
                src="https://static.446.hu/images/favicon.png"
                width={35}
                height={35}
                alt="Brand"
            />
        </Navbar.Brand>
    );
};

export default NavbarBrand;