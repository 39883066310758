import { Editor } from '@tinymce/tinymce-react';
import React, { useRef, useState } from 'react';
import { Form, Button, Modal, InputGroup } from 'react-bootstrap';
import client from '../../../../Utils/apiClient';
import MyStorage from '../../../../Utils/MyStorage';

const ParrotModal = ({ show, pos, callback}) => {
    const editorRef = useRef(null);
    const [state, setState] = useState({
        fields: {
            markerTitle: {
                value: '',
                error: '',
                css: 'is-invalid'
            },
            freq: {
                value: '',
                error: '',
                css: 'is-invalid'
            },
            ctcs: {
                value: '',
                error: '',
                css: 'is-invalid'
            }
        }
    });

    const handleChange = (e) => {
        const field = e.target.name;
        let val = e.target.value;
        let fields = state.fields;
        if(field === "freq"){
            if(val.length === 3 && !val.includes(".")){ val += ".";};
            fields[field]['css'] = (val >= 7) ? 'is-valid' : 'is-invalid';   
        }else if(field === "ctcs"){
            if(val.length === 3 && !val.includes(".")){ val += ".";};
            fields[field]['css'] = (val >= 5) ? 'is-valid' : 'is-invalid';   
        }else if(field === "markerTitle"){
            fields[field]['css'] = (val >= 6) ? 'is-valid' : 'is-invalid';    
        };
        fields[field]['value'] = val;        
        setState({...state, fields});
    }

    const eventSubmit = (e) => {
        e.preventDefault();
        const fields = {
            type: 'parrot',
            lat: pos.lat,
            lon: pos.lon,
            title: state.fields.markerTitle.value,
            freq: state.fields.freq.value,
            ctcs: state.fields.ctcs.value,
            description: editorRef.current.getContent(),
            isActive: true,
            isWorking: true
        };
        client.post('markerAdd', fields, () => {
            let f = state.fields;
            f['markerTitle']['value'] = '';
            f['freq']['value'] = '';
            f['ctcs']['value'] = '';
            f['markerTitle']['css'] = 'is-invalid';
            f['freq']['css'] = 'is-invalid';
            f['ctcs']['css'] = 'is-invalid';
            editorRef.current.setContent('');
            setState({...state, f});
            callback('parrot');
        }, MyStorage.local.get('token'));
    }

    return (
        <Modal show={show}>
            <Form onSubmit={(e) => { eventSubmit(e) }}>
                <Modal.Header>
                    <Modal.Title>Új papagáj létrehozása</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Control className={state.fields.markerTitle.css} type="text" name="markerTitle" id="markerTitle" value={state.fields.markerTitle.value} onChange={handleChange} placeholder="Marker neve" />
                    </Form.Group>
                    <InputGroup className="mb-3">
                        <Form.Control className={state.fields.freq.css} type="text" name="freq" id="freq" value={state.fields.freq.value} onChange={handleChange} placeholder="Frekvencia" />
                        <InputGroup.Text id="freq">MHz</InputGroup.Text>
                        <Form.Control className={state.fields.ctcs.css} type="text" name="ctcs" id="ctcs" value={state.fields.ctcs.value} onChange={handleChange} placeholder="CTCSS" />
                        <InputGroup.Text id="ctcs">Hz</InputGroup.Text>
                    </InputGroup>
                    <Form.Group className="mb-3">
                        <Editor apiKey="ihuzhtbtyuzbwks4d8nuyl9bmu1uq25scfmxd8gjgmmqc5qz" onInit={(_evt, editor) => editorRef.current = editor} init={{ menubar: false, toolbar: 'undo redo | bold italic' }} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { callback('parrot'); }} varian="danger">Mégse</Button>
                    <Button type="submit" variant="success">Mentés</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default ParrotModal;