import React, { useEffect, useState } from 'react';
import IconSet from './Icons';
import { Marker } from 'react-leaflet';
import { isDesktop, isMobile } from 'react-device-detect';
import { Button, ButtonGroup, Modal, Offcanvas } from 'react-bootstrap';
import client from '../../../Utils/apiClient';
import MyStorage from '../../../Utils/MyStorage';
import TimeLine from './TimeLine';
import { MDBContainer, MDBRow } from 'mdb-react-ui-kit';
import { formatDate } from '../../../Utils/Formatter';

const MarkerCombined = ({ args, eventAction, callback }) => {

    const [details, setDetails] = useState({
        id: -1,
        title: '',
        type: '',
        pos: { lat: 0.0, lon: 0.0},
        freq: { up: 0.0, down: 0.0},
        code: { ctcs: 0.0, dcs: ''},
        description: '',
        timeline: [],
        lwr: ''
    });
    const [icon, setIcon] = useState(IconSet['default']);
    const [modal, setModal] = useState(false);
    const [offCanvas, setOffCanvas] = useState(false);

    useEffect(() => {
        let d = details;
        d['id'] = args.id;
        d['type'] = args.type;
        d['pos']['lat'] = args.lat;
        d['pos']['lon'] = args.lon
        d['title'] = args.title;
        d['description'] = args.description;
        d['freq'] = { up: args.upstream_freq, down: args.downstream_freq};
        d['code'] = { ctcs: args.ctcs, dcs: args.dcs };
        if(args.__event){ d['timeline'] = JSON.parse(args.__event) || []; };
        d['lwr'] = args.__lastWorkReport;
        setIcon(IconSet[args.type]);
        if(args.type === "parrot"){ 
            setIcon((args.isWorking) ? IconSet["parrot_a"] : IconSet["parrot_i"]); 
            d['freq'] = args.freq;
        };

        setDetails({...details, d});
    },[]);

    
    /*events*/
    const markerClick = (_marker) => {
        //console.log(_marker.originalEvent.button);
        switch(_marker.originalEvent.button){
            case -1:
                eventAction('markerContextMenu');
                break;
            case 0:
                if(isMobile){ modalShow(true); }
                else if(isDesktop){ canvasShow(true); }
                break;
            case 2:
                console.log("desktop::contextmenu");
                break;
            default: break;
        }
    }
    const modalShow = (_s) => { setModal(_s); }
    const canvasShow = (_s) => { setOffCanvas(_s); }

    //Callback || Feedback
    const markerFeedback = (id,state) => {
        client.post('markerFeedback',{id: id, state:state},(data) => {
            modalShow(false);
            canvasShow(false);
            if(details.type === "parrot") { setIcon((state) ? IconSet['parrot_a'] : IconSet['parrot_i']); };
            let item = { userID: 'N/A', date: formatDate(new Date()), prev: null, now: state };
            let d = details.timeline;
            d.push(item);
            setDetails({ ...details, d });
        },MyStorage.local.get('token'));      
    }
    
    return (
        <>
        <Marker 
            key={details.id}
            position={[details.pos.lat, details.pos.lon]} 
            icon={icon}
            eventHandlers={{
                click: (e) => {markerClick(e)},
                contextmenu: (e) => {markerClick(e)}
            }}
        ></Marker>
        
        <Modal show={modal} onHide={() => { modalShow(false); }}>
            <Modal.Header closeButton>
                <Modal.Title>{details.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(details?.type === "handradio" || details?.type === "deskradio") && (<div dangerouslySetInnerHTML={{ __html: details.description }} />)}
                {(details?.type === "parrot") && (
                    <div>
                        Frekvencia: <b>{details.freq} MHz</b><br/>
                        {(details.code?.ctcs !== null) && (details.code?.dcs === null) && (<>CTCSS: <b>{details.code.ctcs} Hz</b><br/></>)}
                        {(details.code?.dcs !== null) && (details.code?.ctcs === null) && (<>DCS: <b>{details.code.dcs}</b><br/></>)}
                        Utoljára online: <b>{details.lwr}</b>
                        <hr/>
                        <MDBContainer>
                            <MDBRow>
                                <ul className="timeline-3">
                                    {details.timeline
                                        .sort((a,b) => new Date(b.date) - new Date(a.date))
                                        .slice(0,5)
                                        .map((item, index) => ( 
                                            <TimeLine args={item} key={index}/> 
                                        ))
                                    }                        
                                </ul>
                            </MDBRow>
                        </MDBContainer>
                    </div>
                )}
                {(details?.type === "tower") && (
                    <div>
                        Felmenő: <b>{details.freq.up} MHz</b><br/>
                        Lejövő: <b>{details.freq.down} MHz</b><br/>
                        {(details.code?.ctcs !== null) && (<>CTCSS: <b>{details.code.ctcs} Hz</b><br/></>)}
                        {((details.code?.dcs !== null) && (details.code?.dcs !== "")) && (<>DCS: <b>{details.code.dcs}</b><br/></>)}
                        <hr/>
                        <div dangerouslySetInnerHTML={{ __html: details.description}}></div>
                    </div>
                )}
            </Modal.Body>
            {(details?.type === "parrot") && (
                <Modal.Footer>
                    <ButtonGroup>
                        <Button variant="danger" onClick={() => { markerFeedback(details.id, false); }}> Nem működik </Button>
                        <Button variant="success" onClick={() => { markerFeedback(details.id, true); }}> Működik </Button>
                    </ButtonGroup>
                </Modal.Footer>
            )}
        </Modal>

        <Offcanvas show={offCanvas} onHide={() => {canvasShow(false); }}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{details.title}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {(details?.type === "handradio" || details?.type === "deskradio") && (<div dangerouslySetInnerHTML={{ __html: details.description }} />)}
                {(details?.type === "parrot") && (
                    <>
                    <div>
                        Frekvencia: <b>{details.freq} MHz</b><br/>
                        {(details.code?.ctcs !== null) && (details.code?.dcs === null) && (<>CTCSS: <b>{details.code.ctcs} Hz</b><br/></>)}
                        {(details.code?.dcs !== null) && (details.code?.ctcs === null) && (<>DCS: <b>{details.code.dcs}</b><br/></>)}
                        Utoljára online: <b>{details.lwr}</b>
                        <hr/>
                        <ButtonGroup>
                            <Button variant="danger" onClick={() => { markerFeedback(details.id, false); }}> Nem működik </Button>
                            <Button variant="success" onClick={() => { markerFeedback(details.id, true); }}> Működik </Button>
                        </ButtonGroup>
                        <hr/>
                        <MDBContainer>
                            <MDBRow>
                                <ul className="timeline-3">
                                    {details.timeline
                                        .sort((a,b) => new Date(b.date) - new Date(a.date))
                                        .slice(0,10)
                                        .map((item, index) => ( 
                                            <TimeLine args={item} key={index}/> 
                                        ))
                                    }                        
                                </ul>
                            </MDBRow>
                        </MDBContainer>
                    </div>                    
                    </>
                )}
                {(details?.type === "tower") && (
                    <div>
                        Felmenő: <b>{details.freq.up} MHz</b><br/>
                        Lejövő: <b>{details.freq.down} MHz</b><br/>
                        {(details.code?.ctcs !== null) && (<>CTCSS: <b>{details.code.ctcs} Hz</b><br/></>)}
                        {((details.code?.dcs !== null) && (details.code?.dcs !== "")) && (<>DCS: <b>{details.code.dcs}</b><br/></>)}
                        <hr/>
                        <div dangerouslySetInnerHTML={{ __html: details.description}}></div>
                    </div>
                )}
            </Offcanvas.Body>
        </Offcanvas>
        </>
    );
};

export default MarkerCombined;