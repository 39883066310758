import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';

async function fetchMetaData(url) {
    const response = await fetch(`https://api.dub.co/metatags?url=${url}`);
    const data = await response.json();
    return data;
}

const ProfileFeedContent = ({ content }) => {
    const [processedContent, setProcessedContent] = useState([]);

    useEffect(() => {
        const processingContent = async (ctx) => {
            let modifiedContent = ctx;
            let haveLink = false;
            let urlRegex = /https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})/g;
            const containsYouTubeLink = urlRegex.test(modifiedContent);
            if (containsYouTubeLink) {
                const urls = content.match(urlRegex);
                if (urls && urls.length > 0) {
                    urls.forEach((param, index) => {
                        const videoId = param.split("v=")[1];
                        const youtubePreview = `<div><iframe 
                            class="youtubeVideoEmbed" 
                            width="560" 
                            height="315" 
                            src="https://www.youtube.com/embed/${videoId}" 
                            title="YouTube video" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen
                        ></iframe></div>`
                        haveLink = true;
                        modifiedContent = modifiedContent.replace(param, youtubePreview);
                    });
                };
            };

            urlRegex = /(https?:\/\/(?!.*youtube)[^\s]+)/g;
            const containsLink = urlRegex.test(content);
            if (containsLink) {
                const urls = content.match(urlRegex);
                if (urls && urls.length > 0) {
                    if (!haveLink) {
                        const metaDataArray = await Promise.all(urls.map((url) => { url = url.replace('</p>', ''); return fetchMetaData(url); }));
                        metaDataArray.forEach((metaData, index) => {
                            const link = urls[index];
                            const metaPreview = `
                            <div class="card">
                                <div class="row g-0">
                                    <div class="col-md-4">
                                        <img src="${metaData.image.replace('-32x32', '')}" alt="${metaData.title}" class="img-fluid rounded-start"/>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="card-body">
                                            <h5 class="card-title"> ${metaData.title}</h5>
                                            <p class="card-text">${metaData.description}</p>
                                            <a href="${link}" target="_blank" rel="noopener noreferer" class="stretched-link"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>`;
                            modifiedContent = modifiedContent.replace(link, metaPreview);
                            haveLink = true;
                        });
                    }else{
                        urls.forEach((url,index) => {
                            let _link = urls[index];
                            modifiedContent = modifiedContent.replace(_link, `<a href="${_link}" target="_blank">${_link}</a>`);
                            haveLink = true;
                        })
                    }
                };
            };

            setProcessedContent(modifiedContent);
        };
        processingContent(content);
    }, [content]);



    return (
        <MDBRow className="mb-2">
            <MDBCol size={12} dangerouslySetInnerHTML={{ __html: processedContent }}></MDBCol>
        </MDBRow>
    );
};

export default ProfileFeedContent;


const checkForYoutube = (ctx) => {
    const regex = /https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})/;
    const match = ctx.match(regex);
    if (match) {
        const videoID = match[1];
        return videoID;
    } else {
        return false;
    }
}