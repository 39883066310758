import React from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import './Mobile.css';
import TopBar from './Widgets/TopBar';
import BottomBar from './Widgets/BottomBar';
import MobileHome from './Components/MobileHome';
import Terkep from '../Global/Components/Terkep';
import Logout from '../Global/Components/Logout';
import MobileProfile from './Components/MobileProfile';
import MobileSettings from './Components/MobileSettings';
import MobileQSOSend from './Components/MobileQSOSend';
import MobileQSOLogList from './Components/MobileQSOLogList';
import MobileQSODetails from './Components/MobileQSODetails';



const MobileApp = () => {
    const { id } = useParams();

    return (
        <Router>
            <TopBar />
            <Routes>
                <Route path="/" element={<MobileHome />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/terkep" element={<Terkep zoom={6}/>} />
                <Route path="/profile" element={<MobileProfile />} />
                <Route path="/settings" element={<MobileSettings />} />
                <Route path="/qso_log" element={<MobileQSOSend />} />
                <Route path="/qso_list" element={<MobileQSOLogList />} />
                <Route path="/qso/:id" element={<MobileQSODetails />} />
            </Routes>
            <BottomBar />
        </Router>
    );
};

export default MobileApp;