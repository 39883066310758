import React, { useState } from 'react';
import { Button, ListGroup, Modal, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FaIcon from '../../Global/Widgets/FaIcon';
import { isDesktop, isMobile } from 'react-device-detect';
import OffCanvasMobile from './OffCanvasMobile';

const TopBar = () => {
    const [showUserModal, setShowUserModal] = useState(false);
    const [showOffCanvas, setShowOffCanvas] = useState(false);

    return (
        <>
        <OffCanvasMobile show={showOffCanvas} handleClose={() => setShowOffCanvas(false)}/>
        <Navbar id="topBar" fixed="top" className="bg-body-tertiary" bg="dark" data-bs-theme="dark" tabIndex={1}>
            <Nav className="w-100 justify-content-between">
                <Nav.Link as={Button} variant="dark" to="/" style={{ backgroundColor: 'rgba(43,48,53)' }} onClick={() => { setShowOffCanvas(true); }} className="text-left flex-fill">
                    <FaIcon type="solid" icon="bars" />
                </Nav.Link>
                <Nav.Link as={Link} to="/" className="text-center flex-fill">
                    <b>446.hu</b>
                </Nav.Link>

                {isDesktop && (<NavDropdown title={<FaIcon type="solid" icon="user" />} id="user-nav-dropdown" className="text-right flex-fill">
                    <NavDropdown.Item as={Link} to="/logout" className="text-right">
                        <FaIcon type="solid" icon="right-from-bracket" /> Kilépés
                    </NavDropdown.Item>
                </NavDropdown>)}

                {isMobile && (
                    <>
                        <Nav.Link as={Button} variant="dark" onClick={() => { setShowUserModal(true); }} style={{ width: '33%', backgroundColor: 'rgba(43,48,53)' }}>
                            <FaIcon type="solid" icon="user" />
                        </Nav.Link>
                        <Modal show={showUserModal} data-bs-theme="dark" onHide={() => { setShowUserModal(false); }}>
                            <Modal.Header closeButton data-bs-theme="dark" style={{ color: "#fff" }}>
                                Felhasználói menü
                            </Modal.Header>
                            <Modal.Body data-bs-theme="dark">
                                <ListGroup className="userMenu">
                                    <ListGroup.Item variant="dark" as={Link} onClick={() => {setShowUserModal(false); }} to="/profile"><FaIcon type="solid" icon="user" /> Profilom</ListGroup.Item>
                                    <ListGroup.Item variant="dark" as={Link} onClick={() => {setShowUserModal(false); }} to="/settings"><FaIcon type="solid" icon="cogs" /> Beállítások</ListGroup.Item>
                                    <ListGroup.Item variant="danger" as={Link} onClick={() => {setShowUserModal(false); }} to="/logout"><FaIcon type="solid" icon="right-from-bracket" /> Kilépés</ListGroup.Item>                                    
                                </ListGroup>
                            </Modal.Body>
                        </Modal>
                    </>
                )}
            </Nav>
        </Navbar>
        </>
    );
};

export default TopBar;