import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { usePromiseTracker } from 'react-promise-tracker';
import { TailSpin } from 'react-loader-spinner';

const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker();
  /*return ( promiseInProgress && 
    <div className="spinnerBG"
      style={{
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0,0.75)",
      position: "fixed",
      top: "0px",
      left: "0px",
      zIndex: "10002"
    }}>
      <TailSpin color="#fff" height={100} width={100} /><br/>
    </div>
  )*/
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <LoadingIndicator />
    <App />
  </>
);