import "./Desktop.css";
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import Terkep from '../Global/Components/Terkep';
import Logout from '../Global/Components/Logout';
import DesktopHome from './Components/DesktopHome';
import Navbar from './Widgets/Navbar';
import DesktopQSOSend from "./Components/DesktopQSOSend";
import DesktopQSOList from "./Components/DesktopQSOList";
import DesktopQSODetail from "./Components/DesktopQSODetail";
import DesktopProfile from "./Components/DesktopProfile";
import DesktopProfilePublic from "./Components/DesktopProfilePublic";
import DesktopEditProfile from "./Components/DesktopEditProfile";
import DesktopDownloadsIndex from "./Components/Downloads/DesktopDownloadsIndex";
import DesktopGetFile from "./Components/Downloads/DesktopGetFile";

const DesktopApp = () => {
    const { id } = useParams();
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/" element={<DesktopHome />} />
                <Route path="/terkep" element={<Terkep zoom={8}/>} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/qso_log" element={<DesktopQSOSend />} />
                <Route path="/qso_list" element={<DesktopQSOList />} />
                <Route path="/qso/:id" element={<DesktopQSODetail />} />
                <Route path="/profile/*" element={<DesktopProfile />} />                
                <Route path="/profile/:num/*" element={<DesktopProfilePublic />} />
                <Route path="/editProfile" element={<DesktopEditProfile />} />
                <Route path="/downloads/*" element={<DesktopDownloadsIndex />} />

                <Route path="/getFile/*" element={<DesktopGetFile />} />
            </Routes>
        </Router>
    );
};

export default DesktopApp;