export const formatDate = (date, sep = "-") => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}${sep}${month}${sep}${day} ${hours}:${minutes}:${seconds}`;
}

const months = {
    "01": "Január",
    "02": "Február",
    "03": "Március",
    "04": "Április",
    "05": "Május",
    "06": "Június",
    "07": "Július",
    "08": "Augusztus",
    "09": "Szeptember",
    "10": "Október",
    "11": "November",
    "12": "December"
};

export const formatFBDate = (date) => {
    const now = new Date();
    const diffInMs = now - date;
    const diffInMinutes = Math.floor(diffInMs / 60000);
    const diffInHours = Math.floor(diffInMs / 3600000);
    const diffInDays = Math.floor(diffInMs / 86400000);
    if (diffInMinutes < 60) {
        return `${diffInMinutes} perce`;
    } else if (diffInHours < 24) {
        return `${diffInHours} órája`;
    } else if (diffInDays < 7) {
        return `${diffInDays} napja`;
    } else {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${months[month]} ${day}., ${hours}:${minutes}`;
    };
}

export const getElapsedTimeFromLastEvent = (date) => {
    const now = new Date();
    const diffInMs = now - date;
    const diffInMinutes = Math.floor(diffInMs / 60000);
    return diffInMinutes;
}

export const formatFrequency = (freq) => {
    let name = '';
    switch(freq){
        case "446.00625": case "446.006": name = "PMR-1"; break;

        default: name = freq + " MHz"; break;
    };
    return name;
}

export const formatCTCSSorDCSCode = (input) => {
    if(input.includes(".")){
        return `CTCSS: ${input} Hz`;
    }else{
        return `DCS: ${input}`
    };
}

export const formatQSOType = (type) => {
    let t = '';
    switch(type){
        case "/": default: t = 'Fix'; break;
        case "/P": t = 'Kitelepült'; break;
        case "/M": t = 'Mobil'; break;
    };
    return t;
}
export const formatQSOMode = (mode) => {
    let m = '';
    switch(mode){
        case "/D": default: m = "Közvetlen"; break;
        case "/P": m = "Papagájon át"; break;
        case "/A": m = "Átjátszón át"; break;
    };
    return m;
}

export const formatDateForInput = (_date) => {
    let date = new Date(_date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // hónap hozzáadása 0-paddal
    const day = String(date.getDate()).padStart(2, '0'); // nap hozzáadása 0-paddal
    return `${year}-${month}-${day}`;
};

export const formatPerms = (perm) => {
    let string = "";
    switch(perm){
        case 1: default: string = "<div class='badge text-bg-light'>Felhasználó</badge>"; break;
        case 2: string = "<div class='badge text-bg-info'>VIP</div>"; break;
        case 3: string = "<div class='badge text-bg-light'>Tartalomfeltöltő</div>"; break;

        case 97: string = "<div class='badge text-bg-success'>Moderátor</div>"; break;
        case 98: string = "<div class='badge text-bg-primary'>Adminisztrátor</div>"; break;
        case 99: string = "<div class='badge text-bg-warning'>Üzemeltető</div>"; break;
    };
    return string;
}