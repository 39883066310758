import { Editor } from '@tinymce/tinymce-react';
import { MDBCard, MDBCardBody, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import React, { useRef, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import client from '../../../Utils/apiClient';
import MyStorage from '../../../Utils/MyStorage';

const ProfileFeedAddPost = ({ state, callbacks }) => {
    const user = MyStorage.local.get('user');
    const editorRef = useRef(null);
    const [post, setPost] = useState('');
    const [postModal, setPostModal] = useState(false);
    const handlePostSubmit = async (event) => {
        event.preventDefault();
        await new Promise((resolve,reject) => {
            const __uid = MyStorage.local.get('user').uid;
            client.post(
                '/createPost',
                {
                    uid: state.profile.uid,
                    poster_uid: __uid,
                    content: editorRef.current.getContent()
                },
                async (data) => {
                    if(data.success){
                        resolve(data);
                    }else{
                        reject(new Error("Hiba!"));
                    };
                },
                MyStorage.local.get('token')
            )
        }).then((data) => {
            setPost('');
            setPostModal(false);
            callbacks.newpost(data.post);
        })
    };
    return (
        <MDBCard className="fb-styled-card details mb-3">
            <MDBCardBody>
                <MDBRow>
                    <MDBCol size={1}>
                        <img src={user.avatar} alt="profilePic" className="profile-thumb" />
                    </MDBCol>
                    <MDBCol size={11}>
                        <Form.Control
                            type="text"
                            className="fbstyle"
                            placeholder="Mi jár a fejedben?"
                            value={post}
                            onChange={() => setPostModal(!postModal)}
                            onClick={() => setPostModal(!postModal)}
                        ></Form.Control>
                        <Modal show={postModal} onHide={() => setPostModal(!postModal)}>
                            <Form onSubmit={handlePostSubmit}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Bejegyzés létrehozása</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Editor
                                        apiKey="ihuzhtbtyuzbwks4d8nuyl9bmu1uq25scfmxd8gjgmmqc5qz"
                                        onInit={(_evt, editor) => editorRef.current = editor}
                                        init={{
                                            skin: "oxide-dark",
                                            content_css: "dark",
                                            menubar: false,
                                            toolbar: 'undo redo | bold italic'
                                        }}
                                    />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button type="submit" variant="fbstyle" className="text-center">
                                        Létrehozás
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};

export default ProfileFeedAddPost;