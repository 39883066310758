import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';
import FaIcon from '../../Global/Widgets/FaIcon';

const BottomBar = () => {
    return (
        <Navbar id="bottomBar" fixed="bottom" className="bg-body-tertiary" bg="dark" data-bs-theme="dark">
            <Container>
                <Nav className="w-100 justify-content-between">
                    <Nav.Link as={Link} to="/" className="text-center flex-fill">
                        <FaIcon type="solid" icon="house" text="Kezdőlap"/>
                    </Nav.Link>
                    <Nav.Link as={Link} to="/terkep" className="text-center flex-fill">
                        <FaIcon type="solid" icon="map-marker" text="Térkép"/>
                    </Nav.Link>
                    <Nav.Link as={Link} to="/qso_log" className="text-center flex-fill">
                        <FaIcon type="sharp fa-solid" icon="signal-stream" text="QSO log"/>
                    </Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    );
};

export default BottomBar;