import { MDBBadge, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBListGroup, MDBListGroupItem, MDBRow } from 'mdb-react-ui-kit';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import client from '../../Utils/apiClient';
import MyStorage from '../../Utils/MyStorage';
import FaIcon from '../../Global/Widgets/FaIcon';
import { getCoordsByMaidenhead, totalDistance } from '../../Utils/LocationService';
import { MapContainer, Polyline, TileLayer, useMap, Tooltip } from 'react-leaflet';
import MarkerCombined from '../../Global/Components/Terkep/MarkerCombined';
import { formatCTCSSorDCSCode, formatDate, formatFrequency, formatQSOMode, formatQSOType } from '../../Utils/Formatter';
const FitMapToBounds = ({ pointA, pointB }) => { const map = useMap(); useEffect(() => { const bounds = [pointA, pointB]; map.fitBounds(bounds, { padding: [0, 0] }); }, [pointA, pointB, map]); }

const DesktopQSODetail = () => {
    const { id } = useParams();
    const mapRef = useRef();
    const [result, setResult] = useState(false);
    const [center, setCenter] = useState([47.1628, 19.5036]);
    const [zoom, setZoom] = useState(6);
    const [distance, setDistance] = useState(0);

    useEffect(() => {
        const user = MyStorage.local.get('user');
        client.post('getbyid', { callsign: user.callsign, id: id }, (data) => {
            if (!data.success) {
                setResult({ success: false, error: data.message });
            } else {
                setResult({ success: true, item: data.item });
            };
        }, MyStorage.local.get('token'));

    }, [id]);

    useEffect(() => {
        if (result && result.success && result.item) {
            const myPos = getCoordsByMaidenhead(result.item.my_qth);
            const remPos = getCoordsByMaidenhead(result.item.rem_qth);
            let _center = [((myPos.lat + remPos.lat) / 2), ((myPos.lon + remPos.lon) / 2)];
            setCenter(_center);

            let dist = totalDistance(
                { lat: myPos.lat, lon: myPos.lon },
                result.item.pID ? { lat: result.item.lat, lon: result.item.lon } : { lat: remPos.lat, lon: remPos.lon },
                result.item.pID ? { lat: remPos.lat, lon: remPos.lon } : false
            );
            setDistance(dist);
            console.log(dist);
        }
    }, [result])

    return (
        <Container className="mt-base">
            <MDBListGroup>
                {(result?.success === false) && (
                    <>
                        <MDBListGroupItem noBorders color="danger" className="px-3 mb-2 rounded-3">
                            <b>Hiba</b><br />
                            {result?.error}
                        </MDBListGroupItem>
                        <MDBListGroupItem noBorders className="px-3 mb-2 rounded-3">
                            <FaIcon type="sharp fa-light" icon="circle-left" /> Vissza
                            <Link to="/qso_list" className="stretched-link"></Link>
                        </MDBListGroupItem>
                    </>
                )}
                {(result?.success === true) && (
                    <>
                        <MDBRow className="mb-3">
                            <MDBCol size={3}>
                                <MDBCard style={{ borderRadius: '0px' }}>
                                    {(result.item.createdAt && !result.item.approvedAt && !result.item.rejectedAt) && (
                                        <MDBBadge color='warning' className='position-absolute text-bg-warning' style={{ borderRadius: '0px', top: '0px', right: '0px', fontSize: '16px', zIndex: '999' }}>
                                            <FaIcon type="solid" icon="hourglass" /> Függőben
                                        </MDBBadge>
                                    )}
                                    {(result.item.createdAt && result.item.approvedAt && !result.item.rejectedAt) && (
                                        <MDBBadge color='success' className='position-absolute' style={{ borderRadius: '0px', top: '0px', right: '0px', fontSize: '16px', zIndex: '999' }}>
                                            <FaIcon type="solid" icon="ckeck" /> Visszaigazolva
                                        </MDBBadge>
                                    )}
                                    {(result.item.createdAt && !result.item.approvedAt && result.item.rejectedAt) && (
                                        <MDBBadge color='danger' className='position-absolute' style={{ borderRadius: '0px', top: '0px', right: '0px', fontSize: '16px', zIndex: '999' }}>
                                            <FaIcon type="solid" icon="times" /> Elutasítva
                                        </MDBBadge>
                                    )}
                                    <MDBCardBody>
                                        <MDBCardTitle>Ellenállomás</MDBCardTitle>
                                        <MDBCardText>
                                            Hívójel: <b>{result?.item.rem_callsign}</b><br />
                                            Operátor: <b>{result?.item.rem_opName}</b><br />
                                            &nbsp;
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol size={3}>
                                <MDBCard style={{ borderRadius: '0px' }}>
                                    <MDBCardBody>
                                        <MDBCardTitle>Összeköttetés</MDBCardTitle>
                                        <MDBCardText>
                                            Típusa: <b>{formatQSOType(result?.item.type)}</b>, <b>{formatQSOMode(result?.item.mode)}</b><br />
                                            {(result.item.pID) && (<b>({result.item.title})</b>)}<br />
                                            Dátum: <b>{formatDate(new Date(result.item.createdAt), ".")}</b>
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol size={3}>
                                <MDBCard style={{ borderRadius: '0px' }}>
                                    <MDBCardBody>
                                        <MDBCardTitle>Frekvencia</MDBCardTitle>
                                        <MDBCardText>
                                            Frekvencia: <b>{formatFrequency(result?.item.freq)}</b><br />
                                            <b>{formatCTCSSorDCSCode(result?.item.ctcs)}</b><br />
                                            &nbsp;
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol size={3}>
                                <MDBCard style={{ borderRadius: '0px' }}>
                                    <MDBCardBody>
                                        <MDBCardTitle>Lokátor</MDBCardTitle>
                                        <MDBCardText>
                                            Lokátorom: <b>{result?.item.my_qth}</b><br />
                                            Ellen lokátor: <b>{result?.item.rem_qth}</b><br />
                                            Távolság: <b>{distance.toFixed(2)} km</b>
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size={12}>
                                <MapContainer
                                    ref={mapRef}
                                    center={center}
                                    zoom={zoom}
                                    scrollWheelZoom={false}
                                    dragging={false}
                                    doubleClickZoom={false}
                                    zoomControl={false}
                                    id='mapPicture'
                                    style={{
                                        width: 'clamp(500px, 100%, 1265px)',
                                        height: 'clamp(300px, 100vh, 480px)'
                                    }}
                                >
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: 'rgba(0,0,0,0.2)',
                                            zIndex: 1000, // Magas z-index, hogy a térkép fölött legyen
                                        }}
                                    ></div>
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <MarkerCombined
                                        key={0}
                                        args={{
                                            id: 0,
                                            type: 'temp',
                                            lat: getCoordsByMaidenhead(result?.item.my_qth).lat,
                                            lon: getCoordsByMaidenhead(result?.item.my_qth).lon,
                                            title: result?.item.my_callsign,
                                            descriotion: ''
                                        }}
                                        eventAction={() => { }}
                                        callback={() => { }}
                                    />
                                    <MarkerCombined
                                        key={1}
                                        args={{
                                            id: 1,
                                            type: 'handradio',
                                            lat: getCoordsByMaidenhead(result?.item.rem_qth).lat,
                                            lon: getCoordsByMaidenhead(result?.item.rem_qth).lon,
                                            title: result?.item.rem_callsign,
                                            descriotion: ''
                                        }}
                                        eventAction={() => { }}
                                        callback={() => { }}
                                    />
                                    {(result?.item.pID !== null) && (
                                        <MarkerCombined
                                            key={2}
                                            args={{
                                                id: 1,
                                                type: result?.item.markerType,
                                                lat: result?.item.lat,
                                                lon: result?.item.lon,
                                                title: result?.item.rem_callsign,
                                                descriotion: ''
                                            }}
                                            eventAction={() => { }}
                                            callback={() => { }}
                                        />
                                    )}
                                    <FitMapToBounds
                                        pointA={[
                                            getCoordsByMaidenhead(result?.item.my_qth).lat,
                                            getCoordsByMaidenhead(result?.item.my_qth).lon
                                        ]}
                                        pointB={[
                                            getCoordsByMaidenhead(result?.item.rem_qth).lat,
                                            getCoordsByMaidenhead(result?.item.rem_qth).lon
                                        ]}
                                    />
                                    <Polyline
                                        pathOptions={{ color: 'red' }}
                                        positions={[
                                            [getCoordsByMaidenhead(result?.item.my_qth).lat, getCoordsByMaidenhead(result?.item.my_qth).lon],
                                            ...(result?.item.pID ? [[result?.item.lat, result?.item.lon]] : []),
                                            [getCoordsByMaidenhead(result?.item.rem_qth).lat, getCoordsByMaidenhead(result?.item.rem_qth).lon]
                                        ]}
                                    >
                                        <Tooltip
                                            permanent
                                            direction="top"
                                            offset={[0, 0]}
                                            opacity={1}
                                        >
                                            {distance.toFixed(2)} km
                                        </Tooltip>
                                    </Polyline>
                                </MapContainer>

                            </MDBCol>
                        </MDBRow>

                        <MDBListGroupItem noBorders>
                            {/*<MDBRow>
                                <MDBCol size={8}>
                                    <h4>Ellenállomás</h4>
                                </MDBCol>
                                <MDBCol size={4} className="text-right">
                                    {(!result.item.approvedAt && !result.item.rejectedAt) && (<MDBBadge className="ms-2 bg-warning text-bg-warning">Függőben</MDBBadge>)}
                                    {(!result.item.approvedAt && result.item.rejectedAt) && (<MDBBadge className="ms-2 bg-danger text-bg-danger">Elutasítva</MDBBadge>)}
                                    {(result.item.approvedAt && !result.item.rejectedAt) && (<MDBBadge className="ms-2 bg-success text-bg-success">Visszaigazolva</MDBBadge>)}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mb-3">
                                <MDBCol size={6}>
                                    Hívójel: <b>{result?.item.rem_callsign}</b><br/>
                                    Operátor: <b>{result?.item.rem_opName}</b>
                                </MDBCol>
                                <MDBCol size={6} className="text-right">
                                    <b>{formatFrequency(result?.item.freq)}</b><br/>
                                    <i>{formatCTCSSorDCSCode(result?.item.ctcs)}</i>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size={12}>
                                    <h4>Összeköttetés</h4>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size={6}>
                                    Típusa: <b>{formatQSOType(result?.item.type)}</b>
                                </MDBCol>
                                <MDBCol size={6} className="text-right">
                                    Módja: <b>{formatQSOMode(result?.item.mode)}</b>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='mb-3'>
                                <MDBCol size={8}>
                                    Beküldve: <b>{formatDate(new Date(result?.item.createdAt), ".")}</b>
                                </MDBCol>
                                
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size={4}>
                                    Lokátorom: <b>{result?.item.my_qth}</b>
                                </MDBCol>
                                <MDBCol size={4} className="text-right">
                                    Ellen lokátor: <b>{result?.item.rem_qth}</b>
                                </MDBCol>
                                <MDBCol size={4} className="text-right">
                                    Távolság: <b>{getDistanceByQTH(result?.item.my_qth, result?.item.rem_qth).toFixed(2)} km</b>
                                </MDBCol>
                            </MDBRow>*/}
                            <MDBRow>
                                <MDBCol size={12} className="text-center">

                                </MDBCol>
                            </MDBRow>
                        </MDBListGroupItem>
                    </>
                )}
            </MDBListGroup>
        </Container>
    );
};

export default DesktopQSODetail;