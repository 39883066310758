export const validatePersonaForm = (payload) => {
    const errors = {};
    let message = "";
    let isFormValid = true;

    if (!payload || typeof payload.callsign !== "string" || payload.callsign.trim().length === 0 ) {
        isFormValid = false;
        errors.callsign = "A Hívójel mező kitöltése kötelező!";
    };

    if (!payload || typeof payload.opName !== "string" || payload.opName.trim().length === 0 ) {
        isFormValid = false;
        errors.opName = "Az Operátornév mező kitöltése kötelező!";
    };

    /*if (!payload || typeof payload.email !== "string" || payload.email.trim().length === 0 ) {
        isFormValid = false;
        errors.email = "Az E-mail cím mező kitöltése kötelező!";
    };

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(!payload || !emailRegex.test(payload.email)){
        isFormValid = false;
        errors.email = "Kérjük, valós e-mail címet adj meg!"
    };*/

    if(!isFormValid) { message = "Hibásan kitöltött űrlap!"; };

    return { success: isFormValid, message, errors };
}

export const validatePasswordForm = (payload) => {
    const errors = {};
    let message = "";
    let isFormValid = true;

    if (!payload || typeof payload.old_password !== "string" || payload.old_password.trim().length === 0 ) {
        isFormValid = false;
        errors.old_password = "A régi jelszó mező kitöltése kötelező!";
    };

    if (!payload || typeof payload.new_password !== "string" || payload.new_password.trim().length === 0 ) {
        isFormValid = false;
        errors.new_password = "Az új jelszó mező kitöltése kötelező!";
    }else if (payload.new_password.length < 8){
        isFormValid = false;
        errors.new_password = "A jelszónak legalább 8 karakterből kell állnia!";
    }else if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/.test(payload.new_password)){
        isFormValid = false;
        errors.new_password = "A jelszónak tartalmaznia kell kisbetűt, nagybetűt és számot!";
    }

    if (!payload || typeof payload.new_password_rep !== "string" || payload.new_password_rep.trim().length === 0 ) {
        isFormValid = false;
        errors.new_password_rep = "Az új jelszó megerősítése mező kitöltése kötelező!";
    }else if (payload.new_password_rep.length < 8){
        isFormValid = false;
        errors.new_password_rep = "A jelszó megerősítésének legalább 8 karakterből kell állnia!";
    }else if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/.test(payload.new_password_rep)){
        isFormValid = false;
        errors.new_password_rep = "A jelszónak tartalmaznia kell kisbetűt, nagybetűt és számot!";
    };

    if(!payload || payload.new_password !== payload.new_password_rep){
        isFormValid = false;
        errors.new_password_rep = "A jelszavak nem egyeznek meg!";
    }


    if(!isFormValid) { message = "Hibásan kitöltött űrlap!"; };

    return { success: isFormValid, message, errors };

}