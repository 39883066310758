import React, { useEffect, useState } from 'react';
import client from '../../../Utils/apiClient';
import MyStorage from '../../../Utils/MyStorage';
import { Card, ListGroup } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import FaIcon from '../../../Global/Widgets/FaIcon';

const DesktopDownloadsSideTree = () => {
    const [cats, setCats] = useState([]);
    const location = useLocation();

    useEffect(() => {
        client.get('/getDlCategories', (data) => {
            setCats(data.cats);
        }, MyStorage.local.get('token'));
    }, []);

    return (
        <Card className="fb-styled-card">
            <Card.Body>
                <ListGroup className="mb-3 fb-style-list">
                    <ListGroup.Item className={(location.pathname === `/downloads` ? "active" : "")} as={Link} to={`/downloads`}>
                        <FaIcon type="solid" icon="download" /> Letöltések
                    </ListGroup.Item>
                    {(cats.map((item, index) => (
                        <ListGroup.Item className={(location.pathname === `/downloads/${item.alias}` ? "active" : "")} as={Link} to={`/downloads/${item.alias}`}>
                            {item.name}
                        </ListGroup.Item>
                    )))}
                </ListGroup>
            </Card.Body>
        </Card>
    );
};

export default DesktopDownloadsSideTree;