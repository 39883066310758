import React from 'react';
import { Badge } from 'react-bootstrap';

const TimeLine = ({ args }) => {
    const stringify = () => {
        return (args.now) ? "Működik" : "Nem működik";
    };

    return (
        <li className={(args.now) ? "w" : "nw"}>
            <Badge bg={(args.now) ? "success" : "danger"}>{args.date} {stringify()}</Badge>
        </li>
    );
};

export default TimeLine;