import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Container, Form } from 'react-bootstrap';
import MyStorage from '../../Utils/MyStorage';
import { useNavigate } from 'react-router-dom';
import client from '../../Utils/apiClient';

const MobileSettings = () => {
    const editorRef = useRef(null);
    const navigate = useNavigate();
    const [editorState, setEditorState] = useState(false);
    const [state, setState] = useState({
        user: {
            uid: '',
            email: '',
            displayName: '',
            aboutme: '',
            photoURL: '',
        },
        message: {
            show: false,
            variant: '',
            text: ''
        }
    });

    useEffect(() => {
        const data = MyStorage.local.get('user');
        let user = state.user;
        user['uid'] = data.uid;
        user['email'] = data.email;
        user['displayName'] = data.displayName;
        user['aboutme'] = data.aboutme;
        user['photoURL'] = data.photoURL;
        setState({...state, user});
    },[]);

    const handleInit = () => setEditorState(true);
    useEffect(() => {
        try{
            editorRef.current.setContent(state.user.aboutme);
        }catch(e){
            //console.error(e);
        }
    },[editorState, state.user.aboutme]);

    

    const handleChange = (event) => {
        const field = event.target.name;
        let user = state.user;
        user[field] = event.target.value;
        setState({ ...state, user });
    };

    const submitForm = async (event) => {
        event.preventDefault();
        let user = state.user;
        user['aboutme'] = editorRef.current.getContent();
        setState({...state, user});

        MyStorage.local.put('user', state.user);
        client.post('updateProfile',{
            callsign: state.user.displayName,
            opName: state.user.displayName,
            email: state.user.email,
            aboutme: state.user.aboutme,
            id: state.user.uid
        },(data) => {
            let message = {};
            message['variant'] = (data.success) ? "success" : "danger";
            message['text'] = data.message;
            message['show'] = true;
            setState({...state, message});
        }, MyStorage.local.get('token'));
    }

    return (
        <Container className="mobileContainer" data-bs-theme="dark">
            <Form onSubmit={submitForm}>
                <Alert show={state.message.show} variant={state.message.variant} onClick={() => {}}>
                    {state.message.text}
                </Alert>
                <Form.Group className="mb-3">
                    <Form.Label>E-mail cím</Form.Label>
                    <Form.Control type="email" name="email" value={state.user.email} onChange={handleChange}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Megjelenített név</Form.Label>
                    <Form.Control type="text" name="displayName" value={state.user.displayName} onChange={handleChange}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Bemutatkozás</Form.Label>
                    <Editor apiKey="ihuzhtbtyuzbwks4d8nuyl9bmu1uq25scfmxd8gjgmmqc5qz" onInit={(_evt, editor) => {editorRef.current = editor; handleInit(); }} init={{ menubar: false, toolbar: 'undo redo | bold italic' }} >
                        {state.user.aboutme}
                    </Editor>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Button type="submit" variant="success">Mentés</Button>
                </Form.Group>
            </Form>
        </Container>
    );
};

export default MobileSettings;