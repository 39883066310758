import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import FaIcon from '../../../Global/Widgets/FaIcon';
import SBUserField from './SBUserField';
import client from '../../../Utils/apiClient';
import MyStorage from '../../../Utils/MyStorage';

const SidebarOnlineUsers = () => {
    const [onlineUsers, setOnlineUsers] = useState([]);

    const handleSearchClick = () => {

    }
    const handleOptionsClick = () => {

    }

    useEffect(() => {
        client.background('get','/onlineUsers', {}, (data) => {
            setOnlineUsers(data.users);
        },MyStorage.local.get('token'));
    },[]);

    return (
        <>
            <Row>
                <Col md={9} xs={12}>
                    Belépett tagok
                </Col>
                <Col md={3} className="text-right">
                    <FaIcon type="solid" icon="search" style={{cursor:'pointer'}} onClick={handleSearchClick} />
                    <FaIcon type="solid" icon="ellipsis" style={{marginLeft:'10px',cursor:'pointer'}} onClick={handleOptionsClick} />
                </Col>                
            </Row>
            {(onlineUsers) && (onlineUsers.map((user,index) => (
                <SBUserField user={user} key={index}/>
            )))}
            {(onlineUsers.length == 0) && (<center>Nincs elérhető tag</center>)}
        </>
    );
};

export default SidebarOnlineUsers;