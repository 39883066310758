import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import FaIcon from '../../../Global/Widgets/FaIcon';

const NavbarSearchForm = () => {
    return (
        <Form className="d-flex">
            <InputGroup>
                <InputGroup.Text id="basic-addon1"><FaIcon type="solid" icon="search" /></InputGroup.Text>
                <Form.Control
                    type="search"
                    placeholder="Keresés"
                    className="me-2"
                    aria-label="Search"
                />
            </InputGroup>
        </Form>
    );
};

export default NavbarSearchForm;