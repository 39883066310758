import { Adsense } from '@ctrl/react-adsense';

const Ads = () => {
    return (
        <>
        <Adsense
            client="ca-pub-2303113274435979"
            slot="5625853584"
            style={{display: 'block'}}
            layout="in-article"
            format="fluid"
        />     
        </>
    );
};

export default Ads;