import React, { useEffect, useState } from 'react';
import NavbarUserPicture from '../NavbarWidgets/NavbarUserPicture';
import { Badge, Col, Row } from 'react-bootstrap';
import { formatFBDate, getElapsedTimeFromLastEvent } from '../../../Utils/Formatter';

const SBUserField = ({ user }) => {
    const [marker, setMarker] = useState('');
    const [text, setText] = useState('');

    const handleUserClick = () => {

    }

    useEffect(() => {
        let minutes = getElapsedTimeFromLastEvent(new Date(user.lastActivity));
        console.log("???", user.callsign, minutes);
        if(minutes <= 5){
            setMarker('text-bg-success onlineMarker');
            setText('ONLINE')
        }else if(minutes > 6 && minutes <= 15){
            setMarker('text-bg-warning onlineMarker');
            setText(formatFBDate(new Date(user.lastActivity)))
        }else if(minutes > 16 && minutes <= 30){
            setMarker('text-bg-secondary onlineMarker');
            setText(formatFBDate(new Date(user.lastActivity)))
        };
    },[]);

    return (
        <>
            <Row className="onlineUserRow" style={{cursor:'pointer',marginTop:'5px',margonBottom:'5px'}} onClick={handleUserClick} >
                <Col md={2} sm={2} xs={6} style={{cursor:'pointer'}}>
                    <NavbarUserPicture user={user} showName={false} w={36} h={36} />
                    <div className={marker}></div>
                </Col>
                <Col md={6} sm={6} xs={6} style={{paddingTop:'5px', fontWeight: 'bold', cursor: 'pointer'}}>
                    {user.callsign}
                </Col>
                <Col md={4} sm={4} xs={6} style={{paddingTop:'5px', fontWeight: 'bold', cursor: 'pointer'}}>
                    {text}
                </Col>
            </Row>
        </>
    );
};

export default SBUserField;