import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import client from '../../../Utils/apiClient';
import MyStorage from '../../../Utils/MyStorage';
import { Button, ButtonGroup, Card, Col, Row } from 'react-bootstrap';
import FaIcon from '../../../Global/Widgets/FaIcon';

const DesktopDownloadsListFiles = () => {
    const location = useLocation();
    const alias = location.pathname.split('/').slice(-1);
    
    const [files, setFiles] = useState([]);
    
    useEffect(() => {
        client.post('/getDlFiles', {id: alias}, (data) => { setFiles(data.files); },MyStorage.local.get('token'));
    },[]);

    return (
        <Row>
            {(files.map((item,index) => (
                <Col md={4}>
                    <Card className="fb-styled-card">
                        <Card.Img variant="top" src={item.image} style={{maxHeight:'250px', objectFit: 'cover'}}/>
                        <Card.Body>
                            <Card.Title>{item.name}</Card.Title>
                            <Card.Text>{item.description}</Card.Text>
                            <ButtonGroup className='w-100'>
                                <Button className="d-block" variant="fb-field-button" as={Link} to={`${location.pathname}/${item.alias}`}>
                                    <FaIcon type="solid" icon="folder-open" /> Megnyitás
                                </Button>
                                <Button className="d-block" variant="fb-field-button" target='_blank' as={Link} to={`/getFile/${item.alias}`}>
                                    <FaIcon type="solid" icon="download" /> Letöltés
                                </Button>
                            </ButtonGroup>
                        </Card.Body>
                    </Card>
                </Col>
            )))}
        </Row>
    );
};

export default DesktopDownloadsListFiles;