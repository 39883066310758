import { MDBBadge, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBListGroup, MDBListGroupItem, MDBRow } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import client from '../../Utils/apiClient';
import MyStorage from '../../Utils/MyStorage';
import { Container } from 'react-bootstrap';
import { getCoordsByMaidenhead, getDistanceByQTH } from '../../Utils/LocationService';
import { Link } from 'react-router-dom';
import FaIcon from '../../Global/Widgets/FaIcon';
import { formatCTCSSorDCSCode, formatDate, formatFrequency, formatQSOMode, formatQSOType } from '../../Utils/Formatter';
import TerkepSmall from '../Widgets/TerkepSmall';

const DesktopQSOList = () => {
    const [state, setState] = useState({
        list: []
    })

    useEffect(() => {
        let user = MyStorage.local.get('user');
        client.post('/getmyqths', { callsign: user.callsign }, (data) => {
            let list = [];
            data.items.forEach((item) => { 
                list.push(item); 
                console.log(item);
            });
            setState({ ...state, list });
        }, MyStorage.local.get('token'));
    }, []);

    return (
        <Container className="mt-base">
            <MDBRow className='fb-styled-card' style={{paddingTop:'10px',paddingBottom:'10px'}}>
                {state.list
                    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                    .slice(0, 20)
                    .map((item, index) => (
                        <MDBCol size={4} key={index}>
                            <MDBCard>
                                {(item.createdAt && !item.approvedAt && !item.rejectedAt) && (
                                    <MDBBadge color='warning' className='position-absolute text-bg-warning' style={{borderRadius: '0px', top:'0px', right:'0px', fontSize: '16px', zIndex: '999'}}>
                                        <FaIcon type="solid" icon="hourglass" /> Függőben
                                    </MDBBadge>
                                )}
                                {(item.createdAt && item.approvedAt && !item.rejectedAt) && (
                                    <MDBBadge color='success' className='position-absolute' style={{borderRadius: '0px', top:'0px', right:'0px', fontSize: '16px', zIndex: '999'}}>
                                        <FaIcon type="solid" icon="check" /> Visszaigazolva
                                    </MDBBadge>
                                )}
                                {(item.createdAt && !item.approvedAt && item.rejectedAt) && (
                                    <MDBBadge color='danger' className='position-absolute' style={{borderRadius: '0px', top:'0px', right:'0px', fontSize: '16px', zIndex: '999'}}>
                                        <FaIcon type="solid" icon="times" /> Elutasítva
                                    </MDBBadge>
                                )}
                                <TerkepSmall
                                    posA={[getCoordsByMaidenhead(item.my_qth).lat, getCoordsByMaidenhead(item.my_qth).lon]}
                                    posB={[getCoordsByMaidenhead(item.rem_qth).lat, getCoordsByMaidenhead(item.rem_qth).lon]}
                                    posC={(item.pID != null) ? [item.lat, item.lon] : null}
                                    my={{
                                        title: item.my_callsign,
                                        description: ''
                                    }}
                                    rem={{
                                        title: item.rem_callsign,
                                        description: ''
                                    }}
                                    args={item}
                                />
                                <MDBCardBody>
                                    <MDBCardTitle className="text-center">{item.rem_callsign}</MDBCardTitle>
                                    <MDBCardText>
                                        <MDBRow>
                                            <MDBCol size={7}>
                                                <FaIcon type="solid" icon="calendar-alt" /> {formatDate(new Date(item.createdAt),".")}
                                            </MDBCol>
                                            <MDBCol size={5} className="text-right">
                                                <FaIcon type="solid" icon="ruler" /> {getDistanceByQTH(item.my_qth, item.rem_qth).toFixed(2)} Km
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol size={6}>
                                                <FaIcon type="solid" icon="signal-stream" /> {formatFrequency(item.freq)}
                                            </MDBCol>
                                            <MDBCol size={6} className="text-right">
                                                <FaIcon type="solid" icon="key" /> {formatCTCSSorDCSCode(item.ctcs)}
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol size={6}>
                                                <FaIcon type="solid" icon="globe" /> {formatQSOType(item.type)}
                                            </MDBCol>
                                            <MDBCol size={6} className="text-right">
                                                <FaIcon type="solid" icon="globe" /> {formatQSOMode(item.mode)}
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCardText>
                                </MDBCardBody>
                                <Link to={`/qso/${item.id}`} style={{cursor: 'pointer', zIndex: '1030'}} className="stretched-link"></Link>
                            </MDBCard>
                        </MDBCol>
                    ))
                }
            </MDBRow>
        </Container>
    );
};

export default DesktopQSOList;