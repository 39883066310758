import { MDBBtnGroup, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { Accordion, Button, ButtonGroup, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import MyStorage from '../../Utils/MyStorage';
import FaIcon from '../../Global/Widgets/FaIcon';
import client from '../../Utils/apiClient';
import { getDistanceByQTH } from '../../Utils/LocationService';

const DesktopQSOSend = () => {
    const [formShow, setFormShow] = useState(false);
    const [qthCssClass, setQthCssClass] = useState('is-invalid');
    const [state, setState] = useState({
        message: '',
        user:{
            myQth: '',
            myName: ''
        },
        qth:{
            qth: '',
            date: '',
            callsign: '',
            opName: '',
            freq: '',
            ctcs: '',
            type: '/',
            mode: '/D'
        },
        qthList: []
    });

    const handleChange = (event) => {
        const field = event.target.name;
        if(field === "myQth" && event.target.value.length >= 6){ setQthCssClass('is-valid'); }else{ setQthCssClass("is-invalid"); };
        let user = state.user;
        user[field] = event.target.value;
        setState({...state, user});
    }
    const handleQTHChange = (event) => {
        const field = event.target.name;
        let qth = state.qth;
        qth[field] = event.target.value;
        setState({ ...state, qth });
    };

    const submitQTHForm = async (event) => {
        event.preventDefault();
        let qth = state.qth;
        setState((prevState) => ({
            ...prevState,
            qthList: [...prevState.qthList, qth],
            qth: {qth: '',date: '',callsign: '',opName: '',freq: '',ctcs: '',type: '/',mode: '/D'}
        }));
        setFormShow(false);
    }

    const submitForm = async (event) => {
        event.preventDefault();
        
        let fields = {
            my_callsign: state.user.myName,
            my_qth: state.user.myQth,
            list: JSON.stringify(state.qthList)
        };
        client.post('qthadd', fields, (data) => {
            console.log(data);
        }, MyStorage.local.get('token'));
        
        setState((prevState) => ({
            ...prevState,
            qthList: [],
            message: 'A logok sikeresen beküldve!'
        }));
    }

    useEffect(() => {
        let user = state.user;
        const _user = MyStorage.local.get('user');
        user['myName'] = _user.displayName;
        setState({...state, user});
    },[]);


    return (
        <Container className="mt-base">
            <Modal show={formShow} onHide={() => setFormShow(false)}>
                <Form onSubmit={submitQTHForm}>
                    <Modal.Header closeButton>
                        <Modal.Title><FaIcon type="solid" icon="plus" /> Új kapcsolat létrehozása</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>                    
                        <Form.Group className="mb-3" controlId="qsoForm.myqth">
                            <Form.Label>Ellenállomás QTH kódja</Form.Label>
                            <Form.Control type="text" name="qth" onChange={handleQTHChange} value={state.qth.qth !== undefined ? state.qth.qth : ''}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="qsoForm.myqth">
                            <Form.Label>Dátum</Form.Label>
                            <Form.Control type="datetime-local" name="date" onChange={handleQTHChange} value={state.qth.date}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="qsoForm.myqth">
                            <Row>
                                <Col>
                                    <Form.Label>Hívójel</Form.Label>
                                    <Form.Control type="text" name="callsign" onChange={handleQTHChange} value={state.qth.callsign}/>
                                </Col>
                                <Col>
                                    <Form.Label>Operátornév</Form.Label>
                                    <Form.Control type="text" name="opName" onChange={handleQTHChange} value={state.qth.opName}/>
                                </Col>
                            </Row>                            
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="qsoForm.myqth">
                            <Row>
                                <Col>
                                    <Form.Label>Frekvencia</Form.Label>
                                    <InputGroup>
                                        <Form.Control type="text" name="freq" onChange={handleQTHChange} value={state.qth.freq}/>
                                        <InputGroup.Text>MHz</InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label>CT/DCS</Form.Label>
                                    <Form.Control type="text" name="ctcs" onChange={handleQTHChange} value={state.qth.ctcs}/>
                                </Col>
                            </Row>                            
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="qsoForm.myqth">
                            <Row>
                                <Col>
                                    <Form.Label>Típus</Form.Label>
                                    <Form.Select name="type" onChange={handleQTHChange}>
                                        <option value="/">Stabil</option>
                                        <option value="/P">Kitelepült</option>
                                        <option value="/M">Mobil</option>
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Label>Módja</Form.Label>
                                    <Form.Select name="mode" onChange={handleQTHChange}>
                                        <option value="/D">Közvetlen</option>
                                        <option value="/P">Papagájon át</option>
                                        <option value="/A">Átjátszón át</option>
                                    </Form.Select>
                                </Col>
                            </Row>                            
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup>
                            <Button variant="danger" onClick={() => {setFormShow(false);}}><FaIcon type="solid" icon="times"/> Mégse</Button>
                            <Button variant="success" type="submit"><FaIcon type="solid" icon="save"/> Mentés</Button>
                        </ButtonGroup>
                    </Modal.Footer>
                </Form>
            </Modal>
            
            <Form onSubmit={submitForm}>
                <MDBRow className="fb-styled-card">
                    <MDBCol size={4} md={4} xs={12}>
                        <Form.Group className="mb-3" controlId="qsoForm.myqth">
                            <Form.Label>QTH lokátor</Form.Label>
                            <Form.Control type="text" name="myQth" maxLength={6} className={qthCssClass} onChange={handleChange} value={state.user.myQth}/>
                        </Form.Group>
                    </MDBCol>
                    <MDBCol size={4} md={4} xs={12}>
                        <Form.Group className="mb-3" controlId="qsoForm.myName">
                            <Form.Label>Hívójelem</Form.Label>
                            <Form.Control type="text" name="myName" maxLength={6} onChange={() => {}} value={state.user.myName}/>
                        </Form.Group>
                    </MDBCol>
                    <MDBCol size={4} md={4} xs={12}>
                        <MDBBtnGroup style={{top: '31px'}}>
                            <Button variant="light" onClick={() => { setFormShow(true); }} type="button">
                                <FaIcon type="solid" icon="plus" /> Új kapcsolat
                            </Button>
                            <Button variant="success" type="submit">
                                <FaIcon type="solid" icon="floppy-disk" /> Mentés
                            </Button>
                        </MDBBtnGroup>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol size={12}>
                    <Accordion>
                        {state.qthList.map((qth, index) => (
                            <Accordion.Item eventKey={index} key={index}>
                                <Accordion.Header>
                                    <FaIcon type="solid" icon="user" />&nbsp;&nbsp;{qth.callsign} |&nbsp;
                                    {qth.freq} MHz, CT/DCS: {qth.ctcs}
                                </Accordion.Header>
                                <Accordion.Body>
                                    QTH: <b>{qth.qth}</b>, Távolság: <b>{getDistanceByQTH(state.user.myQth, qth.qth).toFixed(2)} km</b><br/>
                                    Időpont: <b>{qth.date}</b><br/>
                                    Típus: <b>{qth.type}</b>, Módja: <b>{qth.mode}</b>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                    </MDBCol>
                </MDBRow>
            </Form>
        </Container>
    );
};

export default DesktopQSOSend;