import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import FsLightbox from 'fslightbox-react';

const AttachmentItem = ({ atts, item, index }) => {
    const [toggler, setToggler] = useState(false); 
    const [slide, setSlide] = useState(1);
    const [type, setType] = useState('image');
    const [ytLink, setYtLink] = useState('');

    const isImage = () => { 
        const ext = item.split('.').pop(); 
        return (['jpg','jpeg','png','gif'].includes(ext) ? true : false);
    };
    const _atts = JSON.parse(atts);

    const handleClick = (_i, mode) => {
        console.log(_atts);
        if (_i <= _atts.length) {
            setType(mode);
            setSlide(_i);
            setToggler(!toggler);
        };
    }

    return (
        <MDBCol size={2}>
            {(isImage() &&( 
                <img key={(index + 1)} id={(index+1)} onClick={() => {handleClick(index + 1, "image");}} src={item} alt={`att-image`} style={{width:'128px',height:'128px', objectFit: 'cover'}} />
            ))}
            <FsLightbox toggler={toggler} type={type} sources={_atts} slide={slide}/>
        </MDBCol>
    )
}

const ProfilPostAttachmentBox = ({ att }) => {
    const [ atts, setAtts ] = useState([]);
    
    useEffect(() => {
        const _att = JSON.parse(att);
        setAtts(_att);
    },[]);
    
    return (
       <MDBRow style={{ paddingBottom: '5px', borderBottom: '1px solid #3e4042'}}>
            {(atts.map((item,index) => (
                <AttachmentItem item={item} index={index} atts={att} key={`att-${index}`} />
            )))}
       </MDBRow>
    );
};

export default ProfilPostAttachmentBox;