import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { auth } from '../../Utils/Firebase';
import FaIcon from '../../Global/Widgets/FaIcon';
import { Link } from 'react-router-dom';
import Ads from '../../Global/Widgets/Ads';
import StatBar from '../Widgets/StatBar';
import MyStorage from '../../Utils/MyStorage';

const MobileHome = () => {
    const [state, setState] = useState({
        user: {
            displayName: ''
        }
    });
    useEffect(() => {
        let user = state.user;
        const _user = MyStorage.local.get('user');
        user['displayName'] = _user.displayName;
        setState({...state, user});
    },[]);


    return (
        <Container className="mobileContainer">
            <Card data-bs-theme="dark" style={{marginTop: "0", width: "100%"}} className="mb-3">
                <Card.Body>
                    <b>Szia {state.user.displayName || `Kedves Barátom`}!</b><br/>
                    Üdvözöllek a <b>446.hu Webalkalmazásában!</b>
                </Card.Body>
            </Card>
            <StatBar />
            <Card data-bs-theme="dark" style={{marginTop: "0", width: "100%"}} className="mb-3">
                <Card.Body>
                    <Row>
                        <Col xs={2}>
                            <FaIcon type="regular" icon="map" size="3x" />
                        </Col>
                        <Col xs={10}>
                            <h2 style={{position:"relative", top:"7px", left: "10px"}}>Térkép</h2>
                        </Col>
                    </Row>
                    <Link to="/terkep" className="stretched-link"></Link>
                </Card.Body>
            </Card>
            <Card data-bs-theme="dark" style={{marginTop: "0", width: "100%"}} className="mb-3">
                <Card.Body>
                    <Row>
                        <Col xs={2}>
                            <FaIcon type="solid" icon="signal-stream" size="3x" />
                        </Col>
                        <Col xs={10}>
                            <h2 style={{position:"relative", top:"7px", left: "10px"}}>QSO log beküldése</h2>
                        </Col>
                    </Row>
                    <Link to="/qso_log" className="stretched-link"></Link>
                </Card.Body>
            </Card>
            <Ads />
        </Container>
    );
};

export default MobileHome;