import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import FaIcon from "../../Global/Widgets/FaIcon";
import { Button, ButtonGroup, Form, InputGroup, Modal } from "react-bootstrap";
import client from "../../Utils/apiClient";
import MyStorage from "../../Utils/MyStorage";
import { Link } from "react-router-dom";
import { formatPerms } from "../../Utils/Formatter";


const ProfileHeader = ({ user, callback, isMyOwn = true }) => {
    console.log(user);
    const [coverModal, setCoverModal] = useState(false);
    const [coverFile, setCoverFile] = useState(false);
    const [coverImage, setCoverImage] = useState('');

    const [profileModal, setProfileModal] = useState(false);
    const [profileFile, setProfileFile] = useState(false);
    const [profileImage, setProfileImage] = useState('');


    const handleCoverChange = (event) => { const file = event.target.files[0]; if (file) { setCoverImage(file.name); setCoverFile(file); } }
    const handleCoverSubmit = async (event) => {
        event.preventDefault();
        if (!coverFile) { alert("Kérlek, válassz egy file-t!"); return; };
        const formData = new FormData();
        formData.append("file", coverFile);
        formData.append("id", user.profile.uid);
        client.post('/uploadCover', formData, (data) => {
            console.log(data);
            if (data.success) {
                let userData = MyStorage.local.get('user');
                userData.coverImage = data.file
                MyStorage.local.put('user', userData);
                setCoverModal(false);
                setCoverFile(false);
                setCoverImage('');
                callback('updated:cover');
            };
        }, MyStorage.local.get('token'));
    }

    const handleProfileChange = (event) => { const file = event.target.files[0]; if (file) { setProfileImage(file.name); setProfileFile(file); } }
    const handleProfileSubmit = async (event) => {
        event.preventDefault();
        if (!profileFile) { alert("Kérlek, válassz egy file-t!"); return; };
        const formData = new FormData();
        formData.append("file", profileFile);
        formData.append("id", user.profile.uid);
        client.post('/uploadAvatar', formData, (data) => {
            if (data.success) {
                let userData = MyStorage.local.get('user');
                userData.photoURL = data.file
                MyStorage.local.put('user', userData);
                setProfileModal(false);
                setProfileFile(false);
                setProfileImage('');
                callback('updated:cover');
            };
        }, MyStorage.local.get('token'));
    }

    const followPress = () => {

    }

    return (
        <MDBContainer fluid className="profile-cover">
            {/* Borítókép rész */}
            <div style={{ position: "relative" }}>
                <img
                    src={user.profile.cover}
                    alt="Cover"
                    className="img-fluid w-100"
                    style={{ height: "300px", objectFit: "cover" }}
                />

                {(isMyOwn) && (
                    <>
                        <Button variant="light" style={{ position: "absolute", bottom: "10px", right: "13px" }} onClick={() => setCoverModal(true)}>
                            <FaIcon type="solid" icon="camera" /> Borítókép szerkesztése
                        </Button>
                        <Modal show={coverModal} onHide={() => setCoverModal(false)}>
                            <Form onSubmit={handleCoverSubmit}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Borítókép kiválasztása</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <InputGroup className="mb-3">
                                        <Form.Control type="file" style={{ display: 'none' }} name="file" id="file" onChange={handleCoverChange} />
                                        <InputGroup.Text id="basic-addon2"><FaIcon type="solid" icon="camera" /></InputGroup.Text>
                                        <Form.Control
                                            placeholder="Nincs kép kiválasztva"
                                            name="file_upload"
                                            value={coverImage}
                                            readOnly
                                            onClick={() => document.getElementById('file').click()}
                                        />
                                        <Button variant="secondary" onClick={() => document.getElementById('file').click()}>
                                            Tallózás
                                        </Button>
                                    </InputGroup>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ButtonGroup>
                                        <Button variant="secondary" onClick={() => setCoverModal(false)}>Mégse</Button>
                                        <Button type="submit" variant="success" >Mentés</Button>
                                    </ButtonGroup>
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    </>
                )}

                {/* Profilkép */}
                <MDBRow style={{ position: "absolute", bottom: "-80px", left: "20px", alignItems: 'center' }}>
                    <MDBCol size="auto">
                        <img
                            src={user.profile.avatar}
                            alt="Profile"
                            className="rounded-circle avatar"
                            style={{
                                width: "160px",
                                height: "160px",
                                border: "5px solid #212529",
                                cursor: "pointer"
                            }}
                            onClick={() => { if (isMyOwn) { setProfileModal(true); }; }}
                        />
                    </MDBCol>
                    {(isMyOwn) && (
                        <>
                            <Modal show={profileModal} onHide={() => setProfileModal(false)}>
                                <Form onSubmit={handleProfileSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Profilkép kiválasztása</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <InputGroup className="mb-3">
                                            <Form.Control type="file" style={{ display: 'none' }} name="avatar" id="avatar" onChange={handleProfileChange} />
                                            <InputGroup.Text id="basic-addon2"><FaIcon type="solid" icon="camera" /></InputGroup.Text>
                                            <Form.Control
                                                placeholder="Nincs kép kiválasztva"
                                                name="file_upload"
                                                value={profileImage}
                                                readOnly
                                                onClick={() => document.getElementById('avatar').click()}
                                            />
                                            <Button variant="secondary" onClick={() => document.getElementById('avatar').click()}>
                                                Tallózás
                                            </Button>
                                        </InputGroup>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <ButtonGroup>
                                            <Button variant="secondary" onClick={() => setProfileModal(false)}>Mégse</Button>
                                            <Button type="submit" variant="success" >Mentés</Button>
                                        </ButtonGroup>
                                    </Modal.Footer>
                                </Form>
                            </Modal>
                        </>
                    )}
                    <MDBCol size={"auto"}>
                        <h4 className="font-weight-bold mb-0" style={{ color: "#fff", position: "absolute", bottom: "40px", width: "100%" }}>
                            {user.profile.displayName}
                        </h4>
                        <p style={{ color: "#b0b3b8", marginTop: "120px" }}>
                            <span dangerouslySetInnerHTML={{ __html:formatPerms(user.profile.perm)}} ></span> • {user.counts.logs} QSO log • {user.counts.follower} követő
                        </p>
                    </MDBCol>
                </MDBRow>
            </div>

            {/* Gombok és egyéb információk */}
            <MDBRow className="mt-3 profile-action-row">
                <MDBCol size="12" className="d-flex justify-content-end align-items-center">
                    {(isMyOwn) && (
                        <ButtonGroup>
                            <Button as={Link} to="/editProfile" variant="primary" className="mr-1"><FaIcon type="solid" icon="pencil" /> Profil módosítása</Button>
                            {/*<Button variant="light"><FaIcon type="solid" icon="cogs" /> Beállítások</Button>*/}
                        </ButtonGroup>
                    )}
                    {(!isMyOwn) && (
                        <ButtonGroup>
                            <Button as={Link} to="#" variant="secondary" className="mr-1"><FaIcon type="solid" icon="envelope" /> Üzenet</Button>
                        </ButtonGroup>
                    )}
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default ProfileHeader;