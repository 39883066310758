import React, { useEffect, useState } from 'react';
import ProfileFeedItem from './ProfileFeedItem';



const ProfileFeedList = ({ feed }) => {
    const [items, setItems] = useState([]);

    useEffect(()=>{
        let _items = [];
        feed.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((item, index) => { 
            _items.push(item);
        });
        setItems(_items);
    },[feed]);

    return (
        <>
            {items.map((item) => {
                return (<ProfileFeedItem item={item} index={item.id} key={item.id}/>)
            })}
        </>
    );
};

export default ProfileFeedList;