import { MDBCard, MDBCardBody, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { formatFBDate } from '../../../Utils/Formatter';
import FaIcon from '../../../Global/Widgets/FaIcon';
import ProfilPostAttachmentBox from '../ProfilPostAttachmentBox';
import { Button, Form } from 'react-bootstrap';
import ProfileLikeBox from '../ProfileLikeBox';
import MyStorage from '../../../Utils/MyStorage';
import client from '../../../Utils/apiClient';
import ProfileFeedComments from './ProfileFeedComments';
import ProfileFeedContent from './ProfileFeedContent';

const ProfileFeedItem = ({ item, index }) => {
    const location = useLocation();
    const user = MyStorage.local.get('user');
    const [data, setData] = useState({});
    const [likes, setLikes] = useState([]);
    const [countOfLikes, setCountOfLikes] = useState(0);
    const [iLiked, setILiked] = useState(false);    
    const [countOfComments, setCountOfComments] = useState(0);
    const [onMyFeed, setOnMyFeed] = useState(true);
    const checkILiked = () => { return likes.some(item => item.user_id === user.uid); }

    const cb_handleReaction = (method, data = null) => {
        if(method === "add"){
            setILiked(true);
            setCountOfLikes((countOfLikes + 1));
            setLikes(prevLikes => [...prevLikes, data]);
        }else{
            setILiked(false);
            setCountOfLikes((countOfLikes - 1))   ;
            setLikes(prevLikes => prevLikes.filter(like => like.user_id !== user.uid));
        }
    }
    const handleReaction = () => {
        if(iLiked){
            client.post('/remReact',{uid: user.uid, feed_id: item.id, reaction: 'like'},(data) => { cb_handleReaction("rem"); }, MyStorage.local.get('token'));
        }else{
            client.post('/addReact',{uid: user.uid, feed_id: item.id, reaction: 'like'},(data) => { cb_handleReaction("add", data.like); }, MyStorage.local.get('token'));
        }
    }

    const _countComments = async () => {
        await client.post('/countComments', {id: item.id}, (data) => setCountOfComments(data.count), MyStorage.local.get('token'));
    }

    useEffect(() => {
        if(item.uid !== item.poster_uid){ setOnMyFeed(false); }else{ setOnMyFeed(true); };
        setData(item);
        let _likes = JSON.parse(item.likes);
        _likes = _likes.filter(like => like.id !== null && like.user_id !== null && like.username !== null && like.reaction !== null);
        setLikes(_likes);        
        setCountOfLikes(_likes.length);
        setILiked(checkILiked());
        _countComments();
    }, []);

    return (
        <div>
            <MDBCard className="fb-styled-card details mb-3" key={`feed-${index}`}>
                <MDBCardBody>
                    <MDBRow className="mb-2">
                        <MDBCol size={1}>
                            <img src={data.avatar} alt="profilePic" className="profile-thumb" />
                        </MDBCol>
                        <MDBCol size={8}>
                            <Link to={`/profile/${data.poster_uid}`} className='fb-styled-link'>{data.callsign}</Link>
                            {(!onMyFeed && (location.pathname === '/') && (
                                <>
                                    <FaIcon type="solid" icon="right" style={{paddingLeft: '5px', paddingRight: '5px'}} /> 
                                    <Link to={`/profile/${data.uid}`} className='fb-styled-link'>{data.owner_callsign}</Link>
                                </>
                            ))}
                            <div className="fb-styled-meta">{formatFBDate(new Date(data.createdAt))}</div>
                        </MDBCol>
                        <MDBCol size={3} className="text-right d-flex" style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Link className="text-white" onClick={() => alert('OK')}><FaIcon type="solid" icon="ellipsis" /></Link>
                        </MDBCol>
                    </MDBRow>
                    <ProfileFeedContent content={data.content} />
                    {(data.attachments) && (<ProfilPostAttachmentBox att={data.attachments} />)}
                    <MDBRow style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                        <MDBCol size={6}>
                            {(countOfLikes > 0) && (<ProfileLikeBox _likes={likes} count={countOfLikes} />)}
                        </MDBCol>
                        <MDBCol size={6} className="text-right">
                            {countOfComments} hozzászólás
                        </MDBCol>
                    </MDBRow>
                    <MDBRow style={{ borderTop: '1px solid rgb(62,64,66)', paddingTop: '5px', borderBottom: '1px solid rgb(62,64,66)', paddingBottom: '5px' }}>
                        <MDBCol size={6} className='text-center d-grid'>
                            <Button onClick={() => { handleReaction(); }} variant={`fbstyle ${iLiked ? 'active' : ''}`} className="text-center">
                                <FaIcon type="regular" icon="thumbs-up" /> Tetszik
                            </Button>
                        </MDBCol>
                        <MDBCol size={6} className='text-center d-grid'>
                            <Button onClick={() => { }} variant="fbstyle" className="text-center">
                                <FaIcon type="regular" icon="comment" /> Hozzászólás
                            </Button>
                        </MDBCol>
                    </MDBRow>
                    <ProfileFeedComments user={user} item={item} />
                </MDBCardBody>
            </MDBCard>
        </div>
    );
};

export default ProfileFeedItem;