import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import MyStorage from '../../Utils/MyStorage';
import FaIcon from '../../Global/Widgets/FaIcon';
import { Link } from 'react-router-dom';

const MobileProfile = () => {

    const [state, setState] = useState({
        profile: {
            uid: '',
            displayName: '',
            email: '',
            photoURL: ''
        }
    })

    useEffect(() => {
        let profile = {};
        const user = MyStorage.local.get('user');
        profile['uid'] = user.uid;
        profile['displayName'] = user.displayName;
        profile['email'] = user.email;
        profile['photoURL'] = user?.photoURL;
        setState({...state, profile});
    },[]);

    return (
        <Container className="mobileContainer">
            <Card data-bs-theme="dark" style={{marginTop: "0", width: "100%"}} className="mb-3">
                <Card.Body>
                    <Row>
                        <Col xs={3}>
                            <Image src={state.profile?.photoURL} />        
                        </Col>
                        <Col xs={9} className="text-right">
                            <h2>{state.profile?.displayName}</h2>
                            <span>{state.profile?.email}</span>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card data-bs-theme="dark" style={{marginTop: "0", width: "100%"}} className="mb-3">
                <Card.Body>
                    <Row>
                        <Col xs={3}>
                            <FaIcon type="solid" icon="pencil" size="3x" />
                        </Col>
                        <Col xs={9} className="text-right">
                            <h2 style={{position:"relative", top:"7px", left: "10px"}}>Adatok módosítása</h2>
                        </Col>
                    </Row>
                    <Link to="/settings" className="stretched-link"></Link>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default MobileProfile;