import React, { useEffect, useState } from 'react';
import ProfileFeedAddPost from './ProfileFeedAddPost';
import client from '../../../Utils/apiClient';
import MyStorage from '../../../Utils/MyStorage';
import ProfileFeedList from './ProfileFeedList';

const ProfileFeed = ({ state }) => {
    const [posts, setPosts] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit] = useState(5);
    const [hasMore, setHasMore] = useState(true);

    const fetchFeed = async () => {
        if(!hasMore) { return; };
        let feed = new Promise (async (resolve,reject) => {
            await client.post('/getMyFeed', {uid: state.profile.uid, offset: offset, limit: limit}, (data) => 
                setTimeout(() => {resolve(data)}, "500")
            , MyStorage.local.get('token'))
        });
        feed.then((data) => {
            if(data.feed.length > 0){
                const ids = new Set(posts.map(item => item.id));
                const newFeed = data.feed.filter(item => !ids.has(item.id));
                if(newFeed.length > 0){
                    setPosts((prevPosts) => [...prevPosts, ...data.feed]);
                    setOffset((prevOffset) => prevOffset + limit);
                };
            }else{
                setHasMore(false);
            }
        });
    }
    
    fetchFeed();

    useEffect(() => {        
                
        const handleScroll = () => {
            if(window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return; 
            fetchFeed();
        };
        window.addEventListener('scroll', handleScroll);
        return () => { window.removeEventListener('scroll', handleScroll); };
    },[offset,hasMore]);

    const cb_newPost = (data) => {
        setPosts((prevPosts) =>  [...prevPosts, data] );
    }
    return (
        <>
            <ProfileFeedAddPost state={state} callbacks={{ newpost: (data) => { cb_newPost(data) } }} />
            <ProfileFeedList feed={posts} />            
        </>
    );
};

export default ProfileFeed;