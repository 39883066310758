import React from 'react';
import { ListGroup, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FaIcon from '../../Global/Widgets/FaIcon';

const OffCanvasMobile = ({ show, handleClose}) => {
    return (
        <Offcanvas show={show} onHide={handleClose} data-bs-theme="dark" className="slideMenu">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Főmenü</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <ListGroup>
                    <ListGroup.Item as={Link} to="/" onClick={handleClose}><FaIcon type="solid" icon="home" /> Főoldal</ListGroup.Item>
                    <ListGroup.Item as={Link} to="/terkep" onClick={handleClose}><FaIcon type="solid" icon="map" /> Térkép</ListGroup.Item>
                    <ListGroup.Item as={Link} to="/qso_log" onClick={handleClose}><FaIcon type="solid" icon="signal-stream" /> QSO log beküldése</ListGroup.Item>
                    <ListGroup.Item as={Link} to="/qso_list" onClick={handleClose}><FaIcon type="solid" icon="signal-stream" /> Beküldött QSO logok</ListGroup.Item>
                </ListGroup>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default OffCanvasMobile;