import React, { useEffect, useState } from 'react';

const NavbarUserPicture = ({ user, showName, w = 30, h = 30 }) => {
    const [picture, setPicture] = useState("https://static.446.hu/images/noavatar.png");

    useEffect(() => {
        setPicture((user?.avatar) ? user?.avatar : "https://static.446.hu/images/noavatar.png");
    },[]);
    
    return (
        <>
            <img src={picture} alt="profilePic" className="profile-thumb" style={{width: `${w}px`, height: `${h}px`}}/>
            {(showName) && (<span style={{paddingLeft:'5px'}}>{user.callsign}</span>)}
        </>
    );
};

export default NavbarUserPicture;