import React from 'react';
import MyStorage from '../../Utils/MyStorage';
import { Navbar as BNavbar, Container, Nav} from 'react-bootstrap';
import NavbarBrand from './NavbarWidgets/NavbarBrand';
import NavbarSearchForm from './NavbarWidgets/NavbarSearchForm';
import NavbarUserDropdown from './NavbarWidgets/NavbarUserDropdown';


const Navbar = () => {
    const user = MyStorage.local.get('user');

    return (
        <BNavbar expand="lg" bg="dark" data-bs-theme="dark" className="navbar fixed-top bg-dark">
            <Container fluid>
                <NavbarBrand />
                <BNavbar.Toggle aria-controls="navbarItem" />
                <BNavbar.Collapse id="navbarItem">
                    <NavbarSearchForm className="me-auto my-2 my-lg-0"/>
                    <Nav className="ms-auto text-right flex-row-reverse" navbarScroll>
                       <NavbarUserDropdown user={user} />
                    </Nav>
                </BNavbar.Collapse>
            </Container>
        </BNavbar>
    );
};

export default Navbar;