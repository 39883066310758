import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import DesktopDownloadsSideTree from './DesktopDownloadsSideTree';
import { Route, Routes } from 'react-router-dom';
import DesktopDownloadsListCategory from './DesktopDownloadsListCategory';
import DesktopDownloadsListFiles from './DesktopDownloadsListFiles';
import DesktopDownloadShowFile from './DesktopDownloadShowFile';

const DesktopDownloadsIndex = () => {


    return (
        <Container className="mt-base" fluid>
            <Row>
                <Col md={3}>
                    <DesktopDownloadsSideTree  />
                </Col>
                <Col md={9}>
                    <Routes>
                        <Route path="/" element={<DesktopDownloadsListCategory />} />
                        <Route path="/:category" element={<DesktopDownloadsListFiles />} />
                        <Route path="/:category/:file" element={<DesktopDownloadShowFile />} />
                    </Routes>
                </Col>
            </Row>
        </Container>
    );
};

export default DesktopDownloadsIndex;