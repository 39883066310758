import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Ads from '../../../Global/Widgets/Ads';
import client from '../../../Utils/apiClient';
import MyStorage from '../../../Utils/MyStorage';

const DesktopGetFile = () => {
    const location = useLocation();
    const alias = location.pathname.split('/').slice(-1)[0];
    const [timer, setTimer] = useState(5);
    const [variant, setVariant] = useState("secondary");
    const [text, setText] = useState(`Várj ${timer} másodpercet...`);
    const [disabled, setDisabled] = useState(false);
    const [file, setFile] = useState(null);

    const downloadClickHandler = () => {
        if (timer === 0) {
            setTimer(5);
            setText(`Várj ${timer} másodpercet...`);
            setDisabled(true);
            let downloaded = (file.downloaded + 1);
            console.log(downloaded);
            client.background('post','/updateFileByAlias',{alias: alias, dl: downloaded},(data) => {
                console.log(data);
                window.location.replace(file.url);
            },MyStorage.local.get('token'));
        };
    }

    useEffect(() => {
        setTimer(5);
        setDisabled(true);
        let interval = false;
        client.post('/getDlFileByAlias', { alias: alias }, (data) => { setFile(data.files); }, MyStorage.local.get('token'));

        if (!interval) {
            interval = setInterval(() => {
                setTimer(prevTimer => {
                    if (prevTimer === 1) {
                        clearInterval(interval);
                        setText("Letöltés");
                        setDisabled(false);
                        return 0;
                    };
                    let nextTimer = (prevTimer - 1);
                    setText(`Várj ${nextTimer} másodpercet...`);
                    return nextTimer;
                });
            }, 1000);
        };
    }, []);

    return (
        <Container className="mt-base">
            <Row>
                <Col md={12} className="mb-3">
                    <Ads />
                </Col>
            </Row>
            <Row>
                <Col md={{span: 6, offset: 3}} className="mb-3">
                    <Card className="fb-styled-card">
                        <Card.Body>
                            <Card.Title className="text-center"><b>Fájl letöltése</b></Card.Title>
                            <Card.Text className="text-center">
                                <p>Fájlnév: <b>{file?.name}</b></p>
                            </Card.Text>
                            <Button
                                className='d-block w-100'
                                disabled={disabled}
                                onClick={downloadClickHandler}
                                variant={variant}
                            >
                            {text}
                        </Button>
                        </Card.Body>
                    </Card>
                    
                </Col>
            </Row>
            <Row>
                <Col md={12} className="mb-3">
                    <Ads />
                </Col>
            </Row>
        </Container>
    );
};

export default DesktopGetFile;