import { isDesktop, isMobile } from "react-device-detect";
import MyStorage from "./MyStorage";

export const getUserLocation = () => {
    if(navigator.geolocation && isMobile){
        navigator.geolocation.getCurrentPosition(
            (pos) => { 
                MyStorage.local.put('location', {
                    lat: pos.coords.latitude,
                    lon: pos.coords.longitude
                });
             },
            (err) => { 
                console.warn(err);
            }
        );
    }else if(isDesktop){
        console.error("Mobile feature!");
    }else{
        console.error("A geolokáció meghatározása nem lehetséges!");
    }
}

export const getMaidenheadGrid = (lat,lon) => {
    lon += 180;
    lat += 90;
    const lonField = String.fromCharCode(Math.floor(lon / 20) + 'A'.charCodeAt(0));
    const latField = String.fromCharCode(Math.floor(lat / 10) + 'A'.charCodeAt(0));
    const lonSquare = Math.floor((lon % 20) / 2).toString();
    const latSquare = Math.floor(lat % 10).toString();
    const lonSubsquare = String.fromCharCode(Math.floor(((lon % 2) * 12)) + 'A'.charCodeAt(0));
    const latSubsquare = String.fromCharCode(Math.floor(((lat % 1) * 24)) + 'A'.charCodeAt(0));
    return lonField + latField + lonSquare + latSquare + lonSubsquare + latSubsquare;
}

export const getCoordsByMaidenhead = (locator) => {
    const lonField = locator.charCodeAt(0) - 'A'.charCodeAt(0);
    const latField = locator.charCodeAt(1) - 'A'.charCodeAt(0);
    const lonSquare = parseInt(locator.charAt(2), 10);
    const latSquare = parseInt(locator.charAt(3), 10);
    const lonSubsquare = locator.charCodeAt(4) - 'A'.charCodeAt(0);
    const latSubsquare = locator.charCodeAt(5) - 'A'.charCodeAt(0);
    const lon = (lonField * 20 + lonSquare * 2 + lonSubsquare / 12) - 180;
    const lat = (latField * 10 + latSquare + latSubsquare / 24) - 90;
    return { lat, lon };
}

export const calculateDistance = (mypos, rempos) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const lat1 = mypos.lat;
    const lon1 = mypos.lon;
    const lat2 = rempos.lat;
    const lon2 = rempos.lon;
    const R = 6371e3;
    const φ1 = toRad(lat1);
    const φ2 = toRad(lat2);
    const Δφ = toRad(lat2 - lat1);
    const Δλ = toRad(lon2 - lon1);
    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return (R * c) / 1000; //Távolság km-ben
}

export const totalDistance = (pos1, pos2, pos3 = false) => {
    const distance1 = calculateDistance(pos1,pos2);
    const distance2 = (pos3) ? calculateDistance(pos2,pos3) : 0;
    const total = distance1 + distance2;
    return total;
}

export const getDistanceByQTH = (qth1, qth2) => {
    const pos1 = getCoordsByMaidenhead(qth1);
    const pos2 = getCoordsByMaidenhead(qth2);
    return totalDistance(pos1, pos2);
}