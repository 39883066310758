import React from 'react';
import { ListGroup } from 'react-bootstrap';
import FaIcon from '../../Widgets/FaIcon';

const MapCTXMenu = ({ pos, eventAction}) => {
    return (
        <ListGroup variant="dark" style={{position: 'absolute', top: pos?.top, left: pos?.left, zIndex: 10000, display: pos?.display }} className="">
            <ListGroup.Item variant="dark" onClick={() => { eventAction('parrot'); }}> <FaIcon type="solid" icon="map-marker" /> Új papagáj </ListGroup.Item>
            <ListGroup.Item variant="dark" onClick={() => { eventAction('tower'); }}> <FaIcon type="solid" icon="tower-cell" /> Új átjátszó </ListGroup.Item>
            <ListGroup.Item variant="dark" onClick={() => { eventAction('handradio'); }}> <FaIcon type="solid" icon="walkie-talkie" /> Új kézi rádió </ListGroup.Item>
            <ListGroup.Item variant="dark" onClick={() => { eventAction('deskradio'); }}> <FaIcon type="solid" icon="radio" /> Új rádióállomás </ListGroup.Item>
        </ListGroup>
    );
};

export default MapCTXMenu;