import React, { useEffect, useState } from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import ProfileFeedAddPost from '../Widgets/FeedWidgets/ProfileFeedAddPost';
import MyStorage from '../../Utils/MyStorage';
import ProfileFeedList from '../Widgets/FeedWidgets/ProfileFeedList';
import client from '../../Utils/apiClient';
import Ads from '../../Global/Widgets/Ads';
import SidebarMenu from '../Widgets/Sidebar/SidebarMenu';
import SidebarOnlineUsers from '../Widgets/Sidebar/SidebarOnlineUsers';

const DesktopHome = () => {
    const [state, setState] = useState({
        profile: {
            uid: '',
            displayName: '',
            email: '',
            avatar: '',
            coverImage: ''
        },
        counts: {
            logs: 0
        }
    });
    const [posts, setPosts] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit] = useState(5);
    const [hasMore, setHasMore] = useState(true);

    const _getProfile = () => {
        let profile = state.profile;
        let counts = state.counts;
        const user = MyStorage.local.get('user');
        profile['uid'] = user.uid;
        profile['aboutme'] = user.aboutme;
        profile['displayName'] = user.callsign;
        profile['email'] = user.email;
        profile['avatar'] = (user?.avatar) ? user?.avatar : "https://static.446.hu/images/noavatar.png";
        profile['cover'] = (user?.coverImage) ? user?.coverImage : "https://static.446.hu/images/cover_stock.jpg";
        counts['logs'] = 0;
        setState({ ...state, profile });
        setState({ ...state, counts });
    }

    const _fetchFeed = async () => {
        if(!hasMore) { return; };
        await client.post('/getPublicFeed',{offset: offset, limit: limit}, (data) => {
            if(data.feed.length > 0){
                const ids = new Set(posts.map(item => item.id));
                const newFeed = data.feed.filter(item => !ids.has(item.id));
                if(newFeed.length > 0){
                    setPosts((prevPosts) => [...prevPosts, ...data.feed]);
                    setOffset((prevOffset) => prevOffset + limit);
                };
            }else{
                setHasMore(false);
            }
        },MyStorage.local.get('token'));
    }

    const countQSORefs = () => {
        let user = MyStorage.local.get('user');
        client.post('qthcount', {callsign: user.callsign}, (data) => { let counts = state.counts;counts['logs'] = data.count;setState({...state, counts});},MyStorage.local.get('token'));
    };

    useEffect(() => {
        _getProfile();
        _fetchFeed();
        countQSORefs();
    }, []);
    
    useEffect(() => {
        const handleScroll = () => {
            if(window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return; 
            _fetchFeed();
        };
        window.addEventListener('scroll', handleScroll);
        return () => { window.removeEventListener('scroll', handleScroll); };
    },[offset,hasMore]);
    
    const cb_newPost = (data) => { setPosts((prevPosts) =>  [...prevPosts, data] ); }

    return (
        <Container fluid className="mt-base">
            <Row>
                <Col md={3} className="d-none d-md-block">
                    <SidebarMenu />
                    <p>Hirdetés</p>
                    <Ads />
                    <hr/>
                    <p>Események</p>
                </Col>
                <Col md={6}>
                    <ProfileFeedAddPost state={state} callbacks={{ newpost: (data) => { cb_newPost(data); } }}/>
                    <ProfileFeedList feed={posts} />
                </Col>
                <Col md={3} className="d-none d-md-block">
                    <p>Hirdetés</p>
                    <Ads />  
                    <hr/>
                    <SidebarOnlineUsers />             
                </Col>
            </Row>
        </Container>
    );
};

export default DesktopHome;